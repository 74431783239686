@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

.fieldsSection {
  margin-top: 1.5rem;
}

table.fields {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: left;

    &:nth-child(1) {
      width: 1.75rem;
      text-align: left;
    }

    &:nth-child(2) {
      text-align: left;
      padding-right: 0.5rem;
    }

    &:nth-child(3),
    &:nth-child(4) {
      width: 20%;
      text-align: right;
      padding-left: 0.5rem;
    }

    &:nth-child(5) {
      width: 5rem;
      text-align: right;
      padding-left: 0.5rem;
    }
  }

  th {
    padding: 0.2rem 0;

    font-weight: 600;
    border-bottom: solid 1px theme.$separator;
  }

  td {
    padding: 0.3rem 0;

    input[type="text"] {
      width: 100%;
    }
  }

  td.dragHandle {
    color: theme.$text-secondary;
    cursor: grab;
  }

  td.delete button {
    padding: 0.25rem 0rem;
    font-size: 0.85rem;
    font-weight: normal;
    color: theme.$text-error;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        color: color.scale(theme.$text-error, $lightness: -20%);
      }
    }
  }

  tr:nth-child(1) td {
    padding-top: 0.6rem;
  }

  tr.add td {
    text-align: left;
  }
}

.specialFieldName {
  font-weight: 600;
}

// .container {
//   span.diff {

//   }
// }
