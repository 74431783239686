import React from 'react';

import { Modal } from '/src/app/Modal';
import CircleSpinner from './CircleSpinner';
import PulseSpinner from './PulseSpinner';

const Styles = require('./ProgressModal.scss');

const ProgressModal = (props: {
  message: any;
}) => {

  return (
    <Modal modalSize='small' className={Styles.container}>
      {/* <CircleSpinner className={Styles.spinner} /> */}
      <PulseSpinner className={Styles.spinner} />
      <div>{props.message}</div>
    </Modal>
  )
}

export default ProgressModal;