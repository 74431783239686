@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

.store {
  padding: 0.7rem 1.2rem;
  
  .heading {
    display: flex;
    flex-flow: row nowrap;

    .name {
      flex: 1 1 auto;
      padding-bottom: 0.4rem;
      font-weight: 700;
    }

    a {
      flex: 0 0 auto;
      font-size: 0.9rem;

      i {
        margin-left: 0.3rem;
        font-size: 0.75rem;
      }
    }
  }

  .noTills {
    color: theme.$text-secondary;
  }

  .recipients {
    .at {
      font-weight: 700;
    }

    font-weight: 600;
    color: color.scale(theme.$text-green, $lightness: -10%);
  }
  
  .noRecipient {
    font-weight: 600;
    color: theme.$text-error;
  }
}

.store_enter {
  transition: all 150ms;
  opacity: 0;
  transform: translateX(5px);
}

.store_enter_active {
  opacity: 1;
  transform: none;
}