@use '/src/App/Theme.scss' as theme;

.container {
  padding: 0.75rem 0;
}

.tableHeading {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 700;
  background-color: theme.$bkgr;
  border-bottom: solid 1px theme.$separator;
  display: flex;
  align-items: center;

  span {
    flex: 1 1 auto;
  }
}

.tableRow {
  padding: 0.5rem 1rem;
  border-bottom: solid 1px theme.$separator;
  display: flex;
  align-items: center;

  transition: all 0.25s;

  span:first-child {
    flex: 1 1 auto;
  }

  span:last-child {
    flex: 0 0 auto;
  }
}

.tableLegend {
  padding: 0.5rem 1rem;
  font-style: italic;
  color: theme.$text-secondary;
}

.tableRow.enter {
  transition: all 500ms linear;
  opacity: 0;
  transform: translateY(-0px);
}

.tableRow.enterActive {
  opacity: 1;
  transform: none;
}
