import React, { useState } from 'react'
import { LoginBox, Page, Button, FlexSpacer } from '/src/app/Elements';
import Axios from 'axios';
import { useHistory } from 'react-router';
import { useModalContext } from '/src/app/Modal';
import ProgressModal from '/src/comps/ProgressModal';

const ResetPasswordPage = () => {
  let history = useHistory();
  let modalContext = useModalContext();

  let [email, setEmail] = useState<string>('');
  let [requested, setRequested] = useState<boolean>(false);

  let [confirmCode, setConfirmCode] = useState<string>('');
  let [password, setPassword] = useState<string>('');
  let [confirmPassword, setConfirmPassword] = useState<string>('');

  let [working, setWorking] = useState<boolean>(false);
  let [error, setError] = useState<string>(null);

  async function handleRequest() {
    setError(null);

    modalContext.show(<ProgressModal message='Working' />);
    setWorking(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));

      let body = {
        email: email
      };

      await Axios.post('/api/requestResetPassword', body);

      setRequested(true);

    } catch (error) {
      console.error(error);
      setError(error.toString());

    } finally {
      await modalContext.dismiss()
      setWorking(false);
    }
  }

  async function handleUpdate() {
    setError(null);

    if (password != confirmPassword) {
      setError('Password and password confirm do not match');
      return;
    }

    try {
      let body = {
        email: email,
        confirmCode: confirmCode,
        newPassword: password
      };

      await Axios.post('/api/resetPassword', body);

      history.push('/login');

    } catch (error) {
      console.error(error);
      setError(error.toString());
    }
  }

  return (
    <LoginBox>
      <Page.BackLink to='/login'>Back To Login</Page.BackLink>
      <LoginBox.SmallTitle>Reset Password</LoginBox.SmallTitle>

      {error && <LoginBox.Error>{error}</LoginBox.Error>}

      {
        (!requested) ?
          <React.Fragment>
            <label>Email</label>
            <input type='email'
              autoFocus
              disabled={requested}
              value={email} onChange={e => setEmail(e.target.value)}
            />
            <LoginBox.Actions>
              <FlexSpacer />
              <Button buttonStyle='primary'
                disabled={!email || working}
                onClick={handleRequest}>
                Reset Password
              </Button>
            </LoginBox.Actions>
          </React.Fragment>
          :
          <React.Fragment>
            <LoginBox.Message>An email with confirmation code has been sent to your email</LoginBox.Message>

            <label>Confirmation Code</label>
            <input type='text'
              disabled={working}
              value={confirmCode} onChange={e => setConfirmCode(e.target.value)} />

            <label>New Password</label>
            <input type='password'
              disabled={working}
              value={password} onChange={e => setPassword(e.target.value)} />

            <label>New Password, Again</label>
            <input type='password'
              disabled={working}
              value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />

            <LoginBox.Actions>
              <FlexSpacer />
              <Button buttonStyle='primary'
                disabled={!confirmCode || !password || !confirmPassword || working}
                onClick={handleUpdate}>
                Update
              </Button>
            </LoginBox.Actions>
          </React.Fragment>
      }
    </LoginBox>
  );
}

export default ResetPasswordPage;