import React from 'react';

const Styles = require('./DropdownMenu.scss');

const DropdownMenu = (props: {
  children: React.ReactNode;
}) => {
  return (
    <ul className={Styles.container}>
      {props.children}
    </ul>
  );
}

export default DropdownMenu;