import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useModalContext } from '/src/app/Modal';

// Dismiss modal when navigated away
function DismissModal() {
  let modalContext = useModalContext();

  const { pathname } = useLocation();

  useEffect(() => {
    if (modalContext.component) {
      modalContext.dismiss();
    }

  }, [pathname]);

  return null;
}

export default DismissModal;