import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '/src/app/AuthContext';
import { FlexSpacer, Button, LoginBox } from '/src/app/Elements';

// const Styles = require('./LoginPage.scss');

// console.log(`LoginPage styles = ${JSON.stringify(Styles)}`);
// console.log(`S.form = ${JSON.stringify(S.form)}`);

const LoginPage = () => {
  let auth = useAuth();

  let defaultUsername = process.env.NODE_ENV === 'development' ? 'nguyenhoangkhanh@gmail.com' : null;

  let [username, setUsername] = useState<string>(defaultUsername);
  let [password, setPassword] = useState<string>('');
  let [working, setWorking] = useState<boolean>(false);
  let [error, setError] = useState<string>(null);

  let usernameRef = useRef<HTMLInputElement>();

  useEffect(() => {
    // usernameRef.current.focus();

  }, []);

  function handleKey(event: any) {
    if (!username || !password) { return; }

    if (event.keyCode === 13) {
      event.preventDefault();

      handleLogin();
    }
  }

  async function handleLogin() {
    setError(null);
    setWorking(true);

    try {
      await auth.login(username, password);

    } catch (error) {
      setError(error);
      setWorking(false);
    }
  }

  return (
    <LoginBox>
      <LoginBox.Title>Cash Up</LoginBox.Title>

      {error && <LoginBox.Error>{error}</LoginBox.Error>}

      <label htmlFor='username'>Email</label>

      <input type='email'
        id='username' ref={usernameRef}
        autoFocus
        value={username}
        onChange={e => setUsername(e.target.value)}
        onKeyUp={handleKey}
      />

      <label htmlFor='password'>Password</label>

      <input type='password'
        id='password'
        value={password}
        onChange={e => setPassword(e.target.value)}
        onKeyUp={handleKey}
      />

      <LoginBox.Actions>
        <Link to='/resetPassword'>Reset Password</Link>
        <FlexSpacer />
        <Button buttonStyle='primary'
          disabled={!username || !password || working}
          onClick={handleLogin}
        >
          Login
        </Button>
      </LoginBox.Actions>
    </LoginBox>
  )
}

export default LoginPage;