import { useState, useEffect } from "react";

// Creates a random string with digits & characters
export function randomString(length: number) {
  const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  let id = '';

  for (let i = 0; i < length; i++) {
    id += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
  }

  return id;
}

// useMemoryState
let memory = {};

export function useMemoryState<S>(identifier: string, initialState?: any): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [value, setValue] = useState<S>(memory[identifier] ?? initialState);

  useEffect(() => {
    memory[identifier] = value;
  }, [value]);

  return [value, setValue];
}
