@use '/src/App/Theme.scss' as theme;

.container {
  margin-left: auto;
  margin-right: auto;
  width: 15rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  font-weight: 700;

  .spinner {
    padding-top: 1px;
    margin-right: 0.5rem;
  }
}
