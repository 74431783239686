@use 'sass:color';
@use '/src/app/Theme.scss' as theme;

// Global

html {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // font-family: -apple-system, "Helvetica Neue", "Helvetica", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.5;
  font-size: 16px;
  // overflow-x: hidden;

  color: theme.$text;
  background-color: white;

  scroll-behavior: smooth;

  // touch-action: pan-x pan-y pan-left pan-right pan-up pan-down;
  // touch-action: pan-x pan-y;
  
  -webkit-font-smoothing: antialiased;
}

:global html.webview {
  div,
  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

body {
  color: theme.$text;
}

a,
label,
button,
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
span,
div {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: rgba(78, 36, 36, 0); // none doesn't work
  outline: none;
  
  &:focus,
  &:hover {
    outline: none;
  }
}

a {
  padding: 0;
  color: theme.$main-darker;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  @media (hover: hover) {
    &:hover {
      color: color.scale(theme.$main-darker, $lightness: 30%);
    }
  }
}

// Label

label {
  display: block;
  padding-bottom: 0.3rem;
  text-transform: uppercase;
  color: theme.$text-secondary;
}

// Input

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  padding: 0.22rem 0.5rem;
  box-sizing: border-box;
  color: theme.$text;
  border: 1px solid rgba(#c7c7c7, 50%);
  border-radius: 0;
  line-height: 1.5;
  -webkit-appearance: none;
}

// Hx

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

