import React, { useState } from 'react';

import { useAuth } from '/src/app/AuthContext';
import { Page, Pane } from '/src/app/Elements';
import Axios from 'axios';

const Styles = require('./ProfilePage.scss');

const ProfilePage = () => {
  let auth = useAuth();

  let [error, setError] = useState<string>(null);

  /*
  async function handleChange(e: any) {
    setError(null);

    // let checked = e.target.checked;
    // setUpdatedCashupRecipient(e.target.checked);
    
    try {
      let body = {
        
      };

      await Axios.post('/api/currentUser', body);

    } catch (error) {
      setError(error.toString());
      setUpdatedCashupRecipient(null);
    }
  }
  */

  return (
    <React.Fragment>
      <Page.Header title='My Profile' />

      {error && <Page.Error>{error}</Page.Error>}

      <Pane>
        <Pane.Header>Account Details</Pane.Header>

        <Pane.Separator />

        <Pane.Content>
          <Pane.Content.FieldRow>
            <Pane.Content.Field>
              <label>Name</label>
              <span>{auth.userInfo?.name}</span>
            </Pane.Content.Field>
            <Pane.Content.Field>
              <label>Email</label>
              <span>{auth.userInfo?.email}</span>
            </Pane.Content.Field>
          </Pane.Content.FieldRow>
        </Pane.Content>
      </Pane>

    </React.Fragment>
  );
}

export default ProfilePage;