import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

const Styles = require('./DropdownButton.scss');

const DropdownButton = (props: {
  children: React.ReactNode;
  dropdownContent: React.ReactNode;
  [other: string]: any;
}) => {

  let {children, dropdownContent, ...rest} = props;

  let [open, setOpen] = useState<boolean>(false);
  let containerRef = useRef(null);

  function handleClick() {
    setOpen(!open);
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, []);

  return (
    <div ref={containerRef} className={Styles.container}>
      <button onClick={handleClick} {...rest}>{props.children}</button>
      <CSSTransition
        in={open}
        timeout={100}
        classNames={{
          enter: Styles.dropdown_enter,
          enterActive: Styles.dropdown_enter_active,
          enterDone: Styles.dropdown_enter_done,
          exit: Styles.dropdown_exit,
          exitActive: Styles.dropdown_exit_active,
          exitDone: Styles.dropdown_exit_done
        }}
        unmountOnExit>
        <div onClick={() => setOpen(false)}>
          {props.dropdownContent}
        </div>
      </CSSTransition>
    </div>
  )
}

export default DropdownButton;