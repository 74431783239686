import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Axios from 'axios';
import { useMemoryState } from '/src/comps/Helpers';
import { PersonResponse } from '/src/models/Person';
import _ from 'lodash';
import { Page, Button, Pane } from '/src/app/Elements';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

import Styles from './PersonsPage.scss';

const PersonsPage = () => {
  let history = useHistory();

  let [persons, setPersons] = useMemoryState<PersonResponse[]>('persons', null);
  let [error, setError] = useState<string>(null);

  useEffect(() => {
    async function fetch() {
      console.log('Fetching persons');

      try {
        let persons = (await Axios.get('/api/persons')).data as PersonResponse[];

        persons = _.orderBy(persons, x => x.name.toLowerCase());

        setPersons(persons);
        setError(null);

        console.log(`Got persons: ${JSON.stringify(persons, null, 2)}`);

      } catch (error) {
        console.error(error);

        setPersons(null);
        setError(error.toString());
      }
    }

    fetch();
  }, []);

  function handleAddPerson() {
    history.push('/person/create');
  }

  function handleEdit(person: PersonResponse) {
    history.push(`/person/${person.id}`, {
      person: person
    });
  }

  return (
    <div className={Styles.container}>
      <Page.Header title='People'>
        <Button buttonStyle='primary' onClick={handleAddPerson}>
          <i className='far fa-plus fa-sm' style={{ marginRight: '0.3rem' }} />
          Add Person
        </Button>
      </Page.Header>

      {error && <Page.Error>{error}</Page.Error>}

      <TransitionGroup component={null} >
        {
          (persons ?? []).map(person =>
            <CSSTransition key={person.id}
              in={true}
              timeout={500}
              classNames={{
                enter: Styles.person_enter,
                enterActive: Styles.person_enter_active
              }}
            >
              <Pane className={Styles.person}>
                <div className={Styles.heading}>
                  <div className={Styles.name}>{person.name}</div>
                  <a onClick={e => handleEdit(person)}>
                    <i className='far fa-pen' />
                    Edit
                  </a>
                </div>
                <div>{person.email}</div>
              </Pane>
            </CSSTransition>
          )
        }
      </TransitionGroup>
    </div>
  );
}

export default PersonsPage;