import React from 'react';
import { useAuth } from '/src/app/AuthContext';

import DropdownButton from '/src/comps/DropdownButton';
import DropdownMenu from '/src/comps/DropdownMenu';
import { FlexSpacer, Layout } from '/src/app/Elements';

// const duck_svg = require('~/media/duck.svg');
const Styles = require('./NavBar.scss');
const ElementStyles = require('/src/app/Elements.scss');

const NavBar = (props: {
  wide?: boolean;
}) => {
  let auth = useAuth();

  return (
    <nav className={Styles.container}>
      <div className={Styles.layoutInset} />

      {/* Fixer & background */}
      <div className={Styles.fixer}>
        <Layout.Center width={props.wide ? 'wide' : 'narrow'}
          style={{ minWidth: '0' /* Fix issue on small screen */, height: '100%' }}>
          <div className={Styles.content}>
            {/* <img src='~/media/duck.svg' alt='The Naked Duck Logo' />  */}
            {/* <img className={Styles.logo} src={duck_svg} alt='The Naked Duck Logo' /> */}
            <span className={Styles.title}>Cash Up</span>

            <FlexSpacer />

            <DropdownButton
              className={ElementStyles.button_plain}
              dropdownContent={
                <DropdownMenu>
                  <li onClick={() => auth.logout()}>
                    <i className='far fa-arrow-left' style={{ fontSize: '0.9rem' }} />
                    <span>Logout</span>
                  </li>
                </DropdownMenu>
              }>
              <span style={{ fontWeight: 500 }}>
                {/* {auth.authState.role == 'user' ? auth.authState?.name : 'Store'} */}
                {
                  (() => {
                    switch (auth.authState.role) {
                      case 'user': return auth.userInfo?.name;
                      case 'store': return auth.storeInfo?.name;
                    }
                  })()
                }
              </span>
              <i className='fas fa-chevron-down fa-xs' />
            </DropdownButton>
          </div>
        </Layout.Center>
      </div>
    </nav>
  )
}

export default NavBar;