import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Axios from 'axios';
import classNames from 'classnames';
import _ from 'lodash';

import { AppContext } from '/src/app/AppContext';
import { FlashContext } from '/src/app/FlashContext';
import { TillLayoutResponse, TillLayoutFieldContrib } from '/src/models/TillLayout';
import { Page, Pane, FlexSpacer, Button, Legend } from '/src/app/Elements';
import { useModalContext } from '/src/app/Modal';
import DialogModal from '/src/comps/DialogModal';
import { randomString } from '/src/comps/Helpers';

const Styles = require('./TillLayoutPage.scss');

// console.log(`Styles = ${JSON.stringify(Styles)}`);

const TillLayoutPage = () => {
  let flashContext = useContext(FlashContext);
  let modal = useModalContext();

  let location = useLocation<{tillLayout: TillLayoutResponse}>();
  
  let history = useHistory();

  let params = useParams<{ id: string }>();
  let layoutId = params.id; // Creating if null, updating otherwise

  // Initial layout
  let initialLayout: TillLayout_identifableFields;

  if (location.state?.tillLayout) {
    let locationLayout = location.state?.tillLayout;

    initialLayout = {
      name: locationLayout.name,
      actualFields: locationLayout.actualFields.map(x => {
        return {
          id: randomFieldId(),
          name: x.name,
          actualContrib: x.actualContrib,
          expectedContrib: x.expectedContrib
        };
      }),
      expectedFields: locationLayout.expectedFields.map(x => {
        return {
          id: randomFieldId(),
          name: x.name,
          actualContrib: x.actualContrib,
          expectedContrib: x.expectedContrib
        };
      }),
    }
  }

  if (!layoutId) {
    // Creating. Set an initial layout.
    initialLayout = {
      name: '',
      actualFields: [
        {
          id: randomFieldId(),
          name: 'Cash',
          actualContrib: 'add',
          expectedContrib: 'none'
        },
        {
          id: randomFieldId(),
          name: 'EFTPOS',
          actualContrib: 'add',
          expectedContrib: 'none'
        }
      ],
      expectedFields: [
        {
          id: randomFieldId(),
          name: 'Cash',
          actualContrib: 'none',
          expectedContrib: 'add'
        },
        {
          id: randomFieldId(),
          name: 'EFTPOS',
          actualContrib: 'none',
          expectedContrib: 'add'
        }
      ]
    }
  }

  let [layout, setLayout] = useState<TillLayout_identifableFields>(initialLayout);
  let [changed, setChanged] = useState<boolean>(false);
  let [isWorking, setIsWorking] = useState<boolean>(false);
  let [error, setError] = useState<string>(null);

  function setName(name: string) {
    setLayout({
      ...layout,
      name: name
    });

    setChanged(true);
    setError(null);
  }

  function setActualFields(fields: TillLayoutField_identifiable[]) {
    setLayout({
      ...layout,
      actualFields: fields
    });

    setChanged(true);
    setError(null);
  }

  function setExpectedFields(fields: TillLayoutField_identifiable[]) {
    setLayout({
      ...layout,
      expectedFields: fields
    });

    setChanged(true);
    setError(null);
  }

  let differenceFields = [];

  if (layout) {
    for (let i = 0; i < Math.min(layout.actualFields.length, layout.expectedFields.length); ++i) {
      let actual = layout.actualFields[i];
      let expected = layout.expectedFields[i];

      if (actual.name == expected.name && actual.name) {
        differenceFields.push(layout.actualFields[i].name);
      }
    }
  }

  useEffect(() => {
    async function fetch() {
      try {
        let response = await Axios.get(`/api/tillLayout/${layoutId}`);
        let layout = response.data as TillLayoutResponse;

        // console.log(`Got layout: ${JSON.stringify(layout)}`);

        setLayout({
          name: layout.name,
          actualFields: layout.actualFields.map(field => {
            return {
              id: randomFieldId(),
              ...field
            }
          }),
          expectedFields: layout.expectedFields.map(field => {
            return {
              id: randomFieldId(),
              ...field
            }
          })
        });

        setError(null);

      } catch (error) {
        console.log(error);
        setLayout(null);
        setError(error.toString());
      }
    }

    if (layoutId && !initialLayout) {
      fetch();
    }

  }, []);

  function handleDelete() {
    modal.show(
      <DialogModal
        title='Confirm'
        message={<span>Delete till layout <b>{}</b>? This cannot be undone.</span>}
        actions={[
          {
            name: 'Delete', style: 'danger',
            handler: async () => {
              setIsWorking(true);

              try {
                await Axios.delete(`/api/tillLayout/${layoutId}`);

                flashContext.setMessage('Till Layout deleted');

                history.push('/tillLayouts');

              } catch (error) {
                console.log(error);

                setIsWorking(false);

                modal.show(
                  <DialogModal
                    title='Error'
                    message={error.toString()}
                    actions={['close']}
                  />
                );
              }
            }
          },
          'cancel'
        ]}
      />
    );
  }

  async function handleSave() {
    setError(null);

    try {
      validateLayout();

      setIsWorking(true);

      if (layoutId) {
        // Updating
        let body = layout;

        await Axios.post(`/api/tillLayout/${layoutId}`, body);

        flashContext.setMessage('Till Layout updated');
        history.push('/tillLayouts');

      } else {
        // Creating
        let body = layout;

        await Axios.put('/api/tillLayout', body);

        flashContext.setMessage('Till Layout added');
        history.push('/tillLayouts');
      }

    } catch (error) {
      console.log(error);

      setIsWorking(false);
      setError(error);
    }
  }

  function validateLayout() {
    // Layout name
    if (!layout.name) { throw 'Name is required' }

    // Field names
    layout.actualFields.concat(layout.expectedFields).forEach(field => {
      if (!field.name) {
        throw 'Missing field name';
      }
    });

    // Duplicated field names
    function validateDuplicatedFieldNames(fields: TillLayoutField_identifiable[]) {
      let names = fields.map(x => x.name);
      let duplicates = names.filter((name, index) => names.indexOf(name) != index);

      if (duplicates.length > 0) {
        throw `Duplicated field name: ${duplicates[0]}`;
      }
    }

    // Duplicated fields
    validateDuplicatedFieldNames(layout.actualFields);
    validateDuplicatedFieldNames(layout.expectedFields);
  }

  return (
    <React.Fragment>
      <Page.BackLink to='/tillLayouts'>Back To Till Layouts</Page.BackLink>

      <Page.Header title={layoutId == null ? 'Add Till Layout' : 'Edit Till Layout'} />

      {error && <Page.Error>{error}</Page.Error>}

      <Pane>
        <Pane.Content style={{ paddingTop: 0 }}>
          {
            layout ?
              <React.Fragment>
                <Pane.Content.FieldRow>
                  <Pane.Content.Field>
                    <label>Name</label>
                    <input type='text'
                      style={{ width: '16rem' }}
                      value={layout.name}
                      onChange={e => setName(e.target.value)} />
                  </Pane.Content.Field>
                </Pane.Content.FieldRow>

                <div className={Styles.fieldsSection}>
                  <label>Actual Sales Fields</label>
                  <FieldsTable
                    defaultActualContrib='add'
                    defaultExpectedContrib='none'
                    fields={layout.actualFields}
                    onChange={fields => setActualFields(fields)}
                  />
                </div>

                <div className={Styles.fieldsSection}>
                  <label>Expected Sales Fields</label>
                  <FieldsTable
                    defaultActualContrib='none'
                    defaultExpectedContrib='add'
                    fields={layout.expectedFields}
                    onChange={fields => setExpectedFields(fields)}
                  />
                </div>

                <div className={Styles.fieldsSection}>
                  <label>Notes on Difference</label>
                  {/* <span className={Styles.diff}>{differenceFields.length > 0 ? differenceFields.join(', ') : '(None)'}</span> */}
                  {/* <Legend>* When an Actual Sales field and an Expected Sales field are in the same position and have the same name, difference field is shown</Legend> */}
                  <div style={{marginBottom: '0.35rem'}}>
                    <span className={Styles.specialFieldName}>Cash</span>,&nbsp; 
                    <span className={Styles.specialFieldName}>Cash Payout</span>&nbsp;
                    and&nbsp;
                    <span className={Styles.specialFieldName}>EFTPOS</span>&nbsp;
                    are special names used for calculating differences.
                  </div>
                  <div style={{marginBottom: '0.2rem'}}>Cash Difference = Actual Cash + Actual Cash Payout – Expected Cash</div>
                  <div style={{marginBottom: '0.2rem'}}>EFTPOS Difference = Actual EFTPOS – Expected EFTPOS</div>
                </div>

                <Pane.Separator style={{ marginTop: '3rem' }} />

                <Pane.Content>
                  <Pane.Content.Actions style={{ paddingTop: '0.5rem' }}>
                    {
                      layoutId &&
                      <Button buttonStyle='link-danger'
                        onClick={handleDelete}>
                        <i className='far fa-trash-alt' style={{ marginRight: '0.33rem' }} />
                        Delete Layout
                      </Button>
                    }

                    <FlexSpacer />
                    {
                      layoutId == null &&
                      <Button buttonStyle='secondary'
                        onClick={() => history.push(`/tillLayouts`)}>
                        Cancel
                      </Button>
                    }
                    <Button buttonStyle='primary'
                      disabled={!changed || isWorking}
                      onClick={handleSave}>
                      {layoutId ? 'Save Changes' : 'Add Layout'}
                    </Button>
                  </Pane.Content.Actions>
                </Pane.Content>
              </React.Fragment>
              :
              <div />
          }
        </Pane.Content>
      </Pane>
    </React.Fragment>
  );
}

const FieldsTable = (props: {
  defaultActualContrib: TillLayoutFieldContrib,
  defaultExpectedContrib: TillLayoutFieldContrib,
  fields: TillLayoutField_identifiable[],
  onChange: (fields: TillLayoutField_identifiable[]) => void;
}) => {

  function updateField(index: number, updateFunc: (field: TillLayoutField_identifiable) => TillLayoutField_identifiable) {
    let fields = [...props.fields];
    fields.splice(index, 1, updateFunc(fields[index]));
    props.onChange(fields);
  }

  function addField() {
    let field: TillLayoutField_identifiable = {
      id: randomFieldId(),
      name: '',
      actualContrib: props.defaultActualContrib,
      expectedContrib: props.defaultExpectedContrib
    };

    let fields = props.fields.concat([field]);
    props.onChange(fields);
  }

  function removeField(index: number) {
    let fields = [...props.fields];
    fields.splice(index, 1);

    props.onChange(fields);
  }


  function handleDragEnd(result) {
    // console.log(`handleDragEnd ${JSON.stringify(result)}`);

    const { destination, source, draggableId } = result;

    if (!destination) { return; }

    if (destination.droppableId == source.droppableId && destination.index == source.index) { return; }

    let newFields = [...props.fields];

    // console.log(`newFields = ${JSON.stringify(newFields)}`);

    let draggedItem = props.fields.find(x => x.id == draggableId);
    newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, draggedItem);

    // console.log(`newFields modified = ${JSON.stringify(newFields)}`);

    props.onChange(newFields);
  }

  return (
    <table className={Styles.fields}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Actual +/–</th>
          <th>Expected +/–</th>
          <th></th>
        </tr>
      </thead>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='list'>
          {provided => (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {
                props.fields.map((field, index) => {
                  return (
                    <Draggable draggableId={field.id} index={index} key={field.id}>
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={classNames({
                            'dragging': snapshot.isDragging
                          })}>
                          <td
                            {...provided.dragHandleProps}
                            className={Styles.dragHandle}>
                            <i className='far fa-bars' />
                          </td>
                          <td>
                            <input type='text'
                              value={field.name}
                              onChange={e => {
                                updateField(index, (field) => {
                                  return {
                                    ...field,
                                    name: e.target.value
                                  }
                                })
                              }}
                            />
                          </td>
                          <td>
                            <select
                              value={field.actualContrib}
                              onChange={e => {
                                updateField(index, (field) => {
                                  return {
                                    ...field,
                                    actualContrib: e.target.value as TillLayoutFieldContrib
                                  }
                                })
                              }}
                            >
                              <option value='add'>Add</option>
                              <option value='subtract'>Subtract</option>
                              <option value='none'>None</option>
                            </select>
                          </td>
                          <td>
                            <select
                              value={field.expectedContrib}
                              onChange={e => {
                                updateField(index, (field) => {
                                  return {
                                    ...field,
                                    expectedContrib: e.target.value as TillLayoutFieldContrib
                                  }
                                })
                              }}
                            >
                              <option value='add'>Add</option>
                              <option value='subtract'>Subtract</option>
                              <option value='none'>None</option>
                            </select>
                          </td>
                          <td className={Styles.delete}>
                            <Button buttonStyle='link-danger'
                              onClick={() => removeField(index)}>
                              Remove
                            </Button>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
      <tfoot>
        <tr className={Styles.add}>
          <td colSpan={5}>
            <Button buttonStyle='link'
              style={{ marginTop: '0.6rem' }}
              onClick={addField}>
              <i className='far fa-plus' style={{ marginRight: '0.3rem' }} />
                    Add Field
            </Button>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

interface TillLayout_identifableFields {
  name: string;
  actualFields: TillLayoutField_identifiable[];
  expectedFields: TillLayoutField_identifiable[];
}

// Till Layout field with id
interface TillLayoutField_identifiable {
  id: string;
  name: string;
  actualContrib: TillLayoutFieldContrib;
  expectedContrib: TillLayoutFieldContrib;
}

function randomFieldId() {
  return randomString(12);
}

export default TillLayoutPage;