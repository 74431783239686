@use '/src/app/Theme.scss' as theme;

.container {
  // width: 3rem;
  display: flex;
  // justify-content: space-between;
  // align-items: center;
}

@mixin pulse($delay) {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: theme.$text;
  
  &:not(last-child) {
    margin-right: 4px;
  }

  animation: pulse 0.6s linear $delay infinite alternate;
}

.pulse1 {
  @include pulse(0s);
}

.pulse2 {
  @include pulse(0.25s);
}

.pulse3 {
  @include pulse(0.5s);
}

@keyframes pulse {
  from {
    opacity: 0.8;
    // transform: scale(1);
  }
  to {
    opacity: 0.05;
    // transform: scale(0.75);
  }
}
