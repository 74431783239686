@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

.tillLayout {
  width: 100%;
  // padding: 0.7rem 1.2rem;
  // box-sizing: border-box;
  // background-color: theme.$bkgr;
  // border: solid 1px color.scale(theme.$separator, $lightness: +50%);
  display: flex;
  flex-flow: column;

  margin-top: 0.8rem;

  .heading {
    display: flex;
    flex-flow: row nowrap;

    .name {
      flex: 1 1 auto;
      margin-right: 1rem;
      font-weight: 700;
      color: theme.$text;
    }
  }

  .section {
    span {
      font-weight: 600;
      margin-bottom: 0.1rem;
    }

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    .fields {
    }
  }

  .actions {
    flex: 0 0 auto;
    margin-left: 1.2rem;
  }
}

.tillLayout_enter {
  transition: all 150ms;
  opacity: 0;
  transform: translateX(5px);
}

.tillLayout_enter_active {
  opacity: 1;
  transform: none;
}
