@use './Theme.scss' as theme;

/*
  How CSSTransition works:
  
  1. xxx_enter is applied
  
  2. xxx_enter_active is immediately applied 
  (xxx_enter is NOT removed, so whatever properties that need to be animated must be in xxx_enter_active)
  
  3. xxx_enter_done is applied, xxx_enter and xxx_enter_active are removed

  Similar for xxx_exit, xxx_exit_active, xxx_exit_done
*/

// Modal window

.modalWindow {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  bottom: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  height: 100%;
  transition: all 0.2s;
}

@mixin modal {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  padding: 1rem 1.75rem;
  background-color: white;
  box-shadow: 0px 5px 20px 5px rgba(black, 15%);

  opacity: 1;
  transform: none; // scaleX(1.03) scaleY(1.03);
  border-radius: 8px;

  display: flex;
  flex-flow: column;
  box-sizing: border-box;

  overflow-y: auto;

  transition: all 0.15s;

  width: 520px;
  max-height: calc(100% - 3.5rem - 1.5rem);
}

.modal {
  @include modal;

}

.modal_small {
  @include modal;
}

@media (max-height: 512px) {
  .modal {
    margin-top: 2rem;
    max-height: calc(100% - 2rem - 2rem);
  }
}

@media (max-width: 512px) {
  .modal {
    margin-top: calc(0.9rem);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 1rem 1rem;
    width: 100%;
    max-height: calc(100% - 0.9rem);
    height: calc(100% - 0.9rem);
    border-radius: 24px;
  }

  .modal_small {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.modalWindow_enter {
  composes: modalWindow;
  background-color: rgba(black, 0);
  backdrop-filter: grayscale(0%);

  .modal, .modal_small {
    opacity: 0;
    transform: scale(1.03);
  }
}

.modalWindow_enter_active,
.modalWindow_enter_done {
  composes: modalWindow;
  background-color: rgba(black, 10%);
  backdrop-filter: grayscale(100%);

  .modal, .modal_small {
    opacity: 1;
    transform: none;
  }
}

.modalWindow_exit {
  composes: modalWindow;
  background-color: rgba(black, 10%);
  backdrop-filter: grayscale(100%);

  .modal, .modal_small {
    opacity: 1;
    transform: none;
  }
}

.modalWindow_exit_active,
.modalWindow_exit_done {
  composes: modalWindow;
  background-color: rgba(black, 0);
  backdrop-filter: grayscale(0%);

  .modal, .modal_small {
    opacity: 0;
    transform: scale(1.03);
  }
}

// Modal components

.modal, .modal_small {
  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    .title {
      flex: 1 1 auto;
      font-size: 1.4rem;
      font-weight: 700;
    }

    button.close {
      flex: 0 0 auto;
      padding: 0;
      margin: 0;
      color: theme.$text-secondary;
      font-size: 1.4rem;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      @media (hover: hover) {
        transition: color 0.15s ease-in-out;

        &:hover {
          color: theme.$text;
        }
      }
    }
  }

  div.actions {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row-reverse;
    margin: 1rem 0 0.5rem 0;

    button {
      min-width: 25%;
      margin-left: 0.9rem;
      padding: 0.6rem 0.8rem 0.65rem;
      border-radius: 3px;
    }
  }
}
