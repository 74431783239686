import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router';
import { addDays, addMonths, format, startOfDay } from 'date-fns';
import Axios from 'axios';
import classNames from 'classnames'
import _ from 'lodash';

import { useModalContext } from '/src/app/Modal';
import { Page, Pane, FlexSpacer, Button, Layout } from '/src/app/Elements';
import CurrencyInput from '/src/comps/CurrencyInput';
import DatePickerModal from '/src/comps/DatePickerModal';
import DialogModal from '/src/comps/DialogModal';
import ProgressModal from '/src/comps/ProgressModal';
import { TillLayoutFieldContrib, TillLayoutResponse, TillLayoutField } from '/src/models/TillLayout';

import Styles from './StoreCashupPage.scss';

// Cash Up Till

interface CashUpInput_Till {
  actualFields: CashUpInput_Till_Field[];
  expectedFields: CashUpInput_Till_Field[];
}

interface CashUpInput_Till_Field {
  name: string;
  actualContrib: TillLayoutFieldContrib;
  expectedContrib: TillLayoutFieldContrib;
  value: number;
}

// Cash Up Report 

interface CashUp {
  cashupDate: Date;
  tills: CashUp_Till[];
  grandTotalActual: number;
  grandTotalExpected: number;
  grandTotalDifference: number;
}

interface CashUp_Till {
  actualFields: CashUp_Till_Field[];
  expectedFields: CashUp_Till_Field[];
  differenceFields: CashUpReport_Till_DifferenceField[];
  totalActual: number;
  totalExpected: number;
  totalDifference: number;
}

interface CashUp_Till_Field {
  name: string;
  actualContrib: TillLayoutFieldContrib;
  expectedContrib: TillLayoutFieldContrib;
  value: number;
}

interface CashUpReport_Till_DifferenceField {
  name: string;
  value: number;
}

const StoreCashUpPage = () => {
  // console.info(`StoreCashUpPage render called`);

  let history = useHistory();
  let modalContext = useModalContext();

  let [date, setDate] = useState<Date>(null);
  let [tills, setTills] = useState<CashUpInput_Till[]>(null);
  let [error, setError] = useState<string>(null);

  // useEffect(() => {
  //   modalContext.show(<ProgressModal message='Submitting' />);
  // }, []);

  async function fetch() {
    try {
      console.log(`Getting tills`);

      let tills: TillLayoutResponse[] = (await Axios.get('/api/currentStore/resolvedTills')).data;

      console.info(`Got tills: ${JSON.stringify(tills, null, 2)}`);

      let cashupTills: CashUpInput_Till[] = tills.map(till => {

        function fieldsFromTillLayoutFields(tlFields: TillLayoutField[]) {
          return tlFields.map(field => {
            return {
              name: field.name,
              actualContrib: field.actualContrib,
              expectedContrib: field.expectedContrib,
              value: 0
            };
          })
        };

        return {
          actualFields: fieldsFromTillLayoutFields(till.actualFields),
          expectedFields: fieldsFromTillLayoutFields(till.expectedFields)
        };
      });

      setTills(cashupTills);
      setError(null);

    } catch (error) {
      setTills(null);
      setError(error.toString());
    }
  }

  function setField(tillIndex: number, column: 'actual' | 'expected', fieldIndex: number, value: number) {
    let newTills = _.cloneDeep(tills);
    let till = newTills[tillIndex];
    let fields = column == 'actual' ? till.actualFields : till.expectedFields;
    fields[fieldIndex].value = value;

    setTills(newTills);
  }

  useEffect(() => {
    fetch();
  }, []);

  function handleSelectDate() {
    modalContext.show(
      <DatePickerModal
        title='Select Date'
        date={date}
        minDate={addDays(addMonths(startOfDay(new Date()), -1), -1)}
        onCommit={date => {
          modalContext.dismiss();

          setDate(date);
        }} />
    )
  }

  let cashup = tills ? cashupFromInput(date, tills) : null;

  function formatCurrency(value: number): string {
    return Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value)
      .replace('-', '–');
  }

  function compareCurrency(x: number, y: number): boolean {
    return Math.abs(x - y) < 0.01;
  }

  async function handleSubmit() {
    if (!date) {
      modalContext.show(<DialogModal title='Submit' message='Please select a cash up date.' />);
      return;
    }

    modalContext.show(
      <DialogModal title='Submit' message='Submit cash up?'
        actions={[
          {
            name: 'Submit',
            style: 'ok',
            autoDismiss: false,
            handler: async () => {
              await modalContext.dismiss();
              submit();
            }
          },
          'cancel'
        ]}
      />
    );
  }

  async function submit() {
    try {
      modalContext.show(<ProgressModal message='Submitting' />);

      await Axios.post('/api/currentStore/cashup', cashup);

      await modalContext.dismiss();

      modalContext.show(
        <DialogModal
          title='Submitted'
          message='Cash Up has been submitted.'
          actions={[
            {
              name: 'Back to Home',
              style: 'ok',
              handler: () => {
                modalContext.dismiss();
                history.push('/');
              }
            }
          ]}
        />
      );

    } catch (error) {
      await modalContext.dismiss();

      modalContext.show(
        <DialogModal
          title='Error'
          message={error.toString()}
          actions={['close']}
        />
      );
    }
  }

  async function handleCancel() {
    if (cashup.grandTotalActual > 0 || cashup.grandTotalExpected > 0) {
      modalContext.show(
        <DialogModal
          title='Discard Cash Up?'
          message='Changes will be lost.'
          actions={[
            {
              name: 'Discard',
              style: 'danger',
              handler: () => {
                history.push('/');
              }
            },
            'cancel'
          ]}
        />
      );

    } else {
      history.push('/');
    }

  }

  // console.info(`report = ${JSON.stringify(report, null, 2)}`);

  return (
    <div className={Styles.container}>
      <Page.BackLink to='/store'>Back To Home</Page.BackLink>
      <Page.Header title='New Cash Up' />
      {error && <Page.Error>{error}</Page.Error>}

      <Pane style={{ marginBottom: '1rem' }}>
        <div className={Styles.date}>
          <span>For Date</span>
          {date && <span>{format(date, 'd MMM yyyy')}</span>}
          <Button buttonStyle='link' onClick={handleSelectDate}>
            <i className='fas fa-calendar-alt' style={{ marginRight: '0.35rem' }} />
            {
              date ? 'Change' : 'Select'
            }

          </Button>
        </div>
      </Pane>

      <TransitionGroup component={null} appear>
        {
          (() => {
            var components = [];

            function pushComponent(component: React.ReactNode) {
              components.push(
                <CSSTransition
                  key={components.length}
                  timeout={200}
                  classNames={{
                    enter: Styles.pane_enter,
                    enterActive: Styles.pane_enter_active
                  }}>
                  {component}
                </CSSTransition>
              );
            }

            if (cashup) {
              cashup.tills.forEach((till, tillIndex) => {
                pushComponent(
                  <Pane key={tillIndex}>
                    <span className={Styles.tillName}>Till #{tillIndex + 1}</span>
                    <table className={Styles.table}>
                      <thead>
                        <tr className={Styles.fieldHeaders}>
                          <th colSpan={2}>Actual Sales</th>
                          <th colSpan={2}>Expected Sales</th>
                          <th>Difference</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (() => {
                            let n = Math.max(till.actualFields.length, till.expectedFields.length);
                            let rows: React.ReactNode[] = [];

                            for (let fieldIndex = 0; fieldIndex < n; ++fieldIndex) {
                              let actual = till.actualFields[fieldIndex];
                              let expected = till.expectedFields[fieldIndex];
                              let diff = till.differenceFields.find(x => x.name == actual?.name);

                              rows.push(
                                <tr key={fieldIndex}>
                                  <td className={Styles.field}>
                                    {
                                      actual &&
                                      <span>{actual.name}</span>
                                    }
                                  </td>
                                  <td className={Styles.field}>
                                    {
                                      actual &&
                                      <CurrencyInput
                                        value={actual.value}
                                        onChange={value => setField(tillIndex, 'actual', fieldIndex, value)}
                                      />
                                    }
                                  </td>
                                  <td className={Styles.field}>
                                    {
                                      expected &&
                                      <span>{expected.name}</span>
                                    }
                                  </td>
                                  <td className={Styles.field}>
                                    {
                                      expected &&
                                      <CurrencyInput
                                        value={expected.value}
                                        onChange={value => setField(tillIndex, 'expected', fieldIndex, value)}
                                      />
                                    }
                                  </td>
                                  <td className={(diff?.value ?? 0) >= 0 ? Styles.difference_positive : Styles.difference_negative}>
                                    {
                                      diff &&
                                      <span>{formatCurrency(diff.value)}</span>
                                    }
                                  </td>
                                </tr>
                              )
                            }

                            return rows;
                          })()
                        }
                      </tbody>
                      <tfoot>
                        <tr className={Styles.fieldTotalHeaders}>
                          <th colSpan={2}>Total Actual Sales</th>
                          <th colSpan={2}>Total Expected Sales</th>
                          <th>Difference</th>
                        </tr>
                        <tr className={Styles.totals}>
                          <td colSpan={2} className={Styles.total}>{formatCurrency(till.totalActual)}</td>
                          <td colSpan={2} className={Styles.total}>{formatCurrency(till.totalExpected)}</td>
                          <td className={till.totalDifference >= 0 ? Styles.total : Styles.total_negative}>
                            {formatCurrency(till.totalDifference)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </Pane>
                );
              });

              pushComponent(
                <div className={Styles.grandTotalPane}>
                  <table className={Styles.table}>
                    <tbody>
                      <tr className={Styles.grandTotalHeaders}>
                        <th colSpan={2}>Grand Total Actual Sales</th>
                        <th colSpan={2}>Grand Total Expected Sales</th>
                        <th>Difference</th>
                      </tr>
                      <tr className={Styles.grandTotals}>
                        <td colSpan={2} className={Styles.grandTotal}>{formatCurrency(cashup.grandTotalActual)}</td>
                        <td colSpan={2} className={Styles.grandTotal}>{formatCurrency(cashup.grandTotalExpected)}</td>
                        <td className={Styles.grandTotal}>{formatCurrency(cashup.grandTotalDifference)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );

              pushComponent(
                <div className={Styles.actions}>
                  <FlexSpacer />
                  <Button buttonStyle='secondary'
                    onClick={() => history.push('/')}>
                    Cancel
                    </Button>
                  <Button buttonStyle='primary' className={Styles.submit}
                    onClick={handleSubmit}>
                    Submit
                    </Button>
                </div>
              );

              pushComponent(
                <div className={Styles.bottomBar}>
                  <div className={Styles.grandTotalPane_fixed}>
                    <Layout.Center className={Styles.grandTotalPane_fixed_center}>
                      <table className={Styles.table}>
                        <tbody>
                          <tr className={Styles.grandTotalHeaders}>
                            <th colSpan={2}>Grand Total Actual Sales</th>
                            <th colSpan={2}>Grand Total Expected Sales</th>
                            <th>Difference</th>
                          </tr>
                          <tr className={Styles.grandTotals}>
                            <td colSpan={2} className={Styles.grandTotal}>{formatCurrency(cashup.grandTotalActual)}</td>
                            <td colSpan={2} className={Styles.grandTotal}>{formatCurrency(cashup.grandTotalExpected)}</td>
                            <td className={Styles.grandTotal}>{formatCurrency(cashup.grandTotalDifference)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Layout.Center>
                  </div>
                  <div className={Styles.actions_fixed}>
                    <Layout.Center
                      style={{ display: 'flex', padding: '0 1.2rem', minWidth: '0px' }}>
                      <FlexSpacer style={{ width: '10000px' }} />
                      <Button buttonStyle='secondary'
                        onClick={handleCancel}>
                        Discard
                      </Button>
                      <Button buttonStyle='primary' className={Styles.submit}
                        onClick={handleSubmit}>
                        Submit
                    </Button>
                    </Layout.Center>
                  </div>

                </div>
              );
            }

            return components;

          })()
        }
      </TransitionGroup>
    </div >
  )
}

/**
 * Creates cash up from date & its tills
 * Dependent fields are calculated
 */
function cashupFromInput(date: Date, tills: CashUpInput_Till[]): CashUp {
  // console.info(`tills = ${JSON.stringify(tills, null, 2)}`);

  let reportTills: CashUp_Till[] = tills.map(till => {

    // Calc difference fields
    let n = Math.max(till.actualFields.length, till.expectedFields.length);
    let differenceFields: CashUpReport_Till_DifferenceField[] = [];

    // Cash diff field
    let actualCashField = till.actualFields.find(x => x.name.toLowerCase() === 'cash');
    let actualCashPayoutField = till.actualFields.find(x => x.name.toLowerCase() === 'cash payout');
    let expectedCashField = till.expectedFields.find(x => x.name.toLowerCase() === 'cash');

    if ((actualCashField || actualCashPayoutField) && expectedCashField) {
      differenceFields.push({
        name: 'Cash',
        value: (actualCashField?.value ?? 0) + (actualCashPayoutField?.value ?? 0) - expectedCashField.value
      });
    }

    // EFTPOS diff field
    let actualEFTPOSField = till.actualFields.find(x => x.name.toLowerCase() === 'eftpos');
    let expectedEFTPOSField = till.expectedFields.find(x => x.name.toLowerCase() === 'eftpos');

    if (actualEFTPOSField && expectedEFTPOSField) {
      differenceFields.push({
        name: 'EFTPOS',
        value: actualEFTPOSField.value - expectedEFTPOSField.value
      });
    }

    /*
    for (let i = 0; i < n; ++i) {
      let actual = till.actualFields[i];
      let expected = till.expectedFields[i];
      if (actual?.name == expected?.name) {
        differenceFields.push({
          name: actual.name,
          value: actual.value - expected.value
        });
      }
    }
    */

    // Calc total actual

    let totalActual = 0;
    let totalExpected = 0;

    till.actualFields.concat(till.expectedFields).forEach(field => {
      if (field.actualContrib == 'add') {
        totalActual += field.value;

      } else if (field.actualContrib == 'subtract') {
        totalActual -= field.value;
      }

      if (field.expectedContrib == 'add') {
        totalExpected += field.value;

      } else if (field.expectedContrib == 'subtract') {
        totalExpected -= field.value;
      }
    });

    // Calc total diff

    let totalDifference = _.sum(differenceFields.map(field => field.value));

    return {
      actualFields: till.actualFields,
      expectedFields: till.expectedFields,
      differenceFields: differenceFields,
      totalActual,
      totalExpected,
      totalDifference
    }
  });

  let report: CashUp = {
    cashupDate: date,
    tills: reportTills,
    grandTotalActual: _.sum(reportTills.map(till => till.totalActual)),
    grandTotalExpected: _.sum(reportTills.map(till => till.totalExpected)),
    grandTotalDifference: _.sum(reportTills.map(till => till.totalDifference))
  };

  return report;
}

export default StoreCashUpPage;