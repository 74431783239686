@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

.date {
  // margin-bottom: 1.5rem;

  span:first-child {
    font-weight: 700;
    margin-right: 1em;
  }

  span:nth-child(2) {
    margin-right: 1em;
  }
}

// Till

.tillName {
  display: inline-block;
  margin-bottom: 0.8rem;
  padding: 0.15rem 0.85rem 0.25rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  // color: white;
  // background-color: theme.$main-green;
  color: theme.$main-compl;
  border: solid 1.5px theme.$main-compl;
  background-color: white;
  border-radius: 2px;
}

// Till Pane

table.table {
  width: 100%;
  border-collapse: collapse;

  tr.fieldHeaders th {
    padding: 0.3rem 0;
    border-bottom: solid 1px theme.$separator;
    vertical-align: bottom;
  }

  tr.fieldTotalHeaders th {
    padding: 0.3rem 0 0rem;
    border-top: solid 1px theme.$separator;
    vertical-align: bottom;
  }

  tr.grandTotalHeaders {
    vertical-align: bottom;
  }

  th:nth-child(1),
  th:nth-child(2) {
    text-align: left;
    width: 40%;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }

  th {
    text-transform: uppercase;
    font-weight: 600;
    padding: 0;
  }

  td {
    color: theme.$text-secondary;
  }

  td.field {
    padding: 0.25rem 0;
    vertical-align: middle;

    span {
      width: 7rem;
      margin-right: 0.5rem;
    }

    input {
      width: 6rem;
      text-align: right;
      margin-right: 1.5rem;

      &.zero {
        color: theme.$text-secondary;
      }
    }
  }

  td.difference_positive {
    color: theme.$text-green;
    font-weight: 600;
  }

  td.difference_negative {
    color: theme.$text-error;
    font-weight: 600;
  }

  tr:first-child {
    td.field,
    td.difference {
      padding-top: 0.5rem;
    }
  }

  tr:last-child {
    td.field,
    td.difference {
      padding-bottom: 1.5rem;
    }
  }

  td.total {
    color: theme.$text-green;
    font-weight: 600;
    font-size: 1.5rem;
  }

  td.total_negative {
    @extend .total;

    color: theme.$text-error;
  }
}

.grandTotalPane,
.grandTotalPane_fixed {
  background-color: theme.$main-green;
  color: white;
  // background-color: theme.$bkgr;
  // color: theme.$text;
  font-weight: 400;

  td.grandTotal {
    color: white;
    // color: theme.$main-green;
    font-weight: 700;
    font-size: 1.45rem;
  }
}

.grandTotalPane {
  margin-top: 0.75rem;
  padding: 1rem 1.25rem;
}

.grandTotalPane_fixed {
  padding: 1rem 0;

  .grandTotalPane_fixed_center {
    display: flex;
    padding: 0 calc(1.25rem);
    min-width: 0;
  }
}

.actions,
.actions_fixed {
  display: flex;

  button {
    text-transform: uppercase;
    padding: 0.6rem 1.5rem;
    margin-left: 1rem;
    // font-weight: 600;
  }

  button.submit {
    font-weight: 700;
  }
}

.actions {
  margin-top: 0.75rem;
  margin-bottom: env(safe-area-inset-bottom);
  padding: 1rem 1.25rem;
  background-color: theme.$bkgr;
}

.actions_fixed {
  padding: 1rem 0;
  padding-bottom: calc(1.25rem + env(safe-area-inset-bottom));
  background-color: theme.$bkgr;
}

.bottomBar {
  position: fixed;
  // padding-top: 1px;
  border: none;
  box-sizing: border-box;
  // background-color: white;
  // background-color: theme.$bkgr;
}

// No bottom bar
.grandTotalPane,
.actions {
  visibility: visible;
  visibility: visible;
}

.bottomBar {
  visibility: hidden;
}

@media (min-height: 576px) and (min-width: 768px) {
  .grandTotalPane,
  .actions {
    margin-top: 0;
    visibility: hidden;
  }

  .bottomBar {
    visibility: visible;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Pane animation
.pane_enter {
  transition: all 200ms;
  opacity: 0;
  transform: translateX(5px);
}

.pane_enter_active,
.pane_enter_done {
  opacity: 1;
  transform: none;
}
