@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

// Layout
// Center contents & limit width on large screens
.layout_center {
  min-width: 720px;
  margin-left: auto;
  margin-right: auto;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  box-sizing: border-box;

  // @media screen and (min-width: 576px) {
  //   width: 540px;
  // }

  // @media screen and (min-width: 768px) {
  //   width: 720px;
  // }

  // @media screen and (min-width: 992px) {
  //   width: 960px;
  // }

  // @media screen and (min-width: 1200px) {
  //   width: 1140px;
  // }

  max-width: 1140px;
}

//
.pane {
  width: 100%;
  padding: 1rem 1.25rem;
  box-sizing: border-box;
  background-color: theme.$bkgr;
  border: solid 1px #f3f3f3;

  & {
    margin-top: 0rem;
  }

  & ~ & {
    margin-top: 0.75rem;
  }

  div.pane_header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 0.4rem;
    font-size: 1rem;
    font-weight: 600;
  }

  div.pane_separator {
    background-color: theme.$separator;
    height: 1px;
  }

  div.pane_content {
    width: 100%;
    box-sizing: border-box;
    padding-top: 1rem;

    div.pane_fieldRow {
      width: 100%;
      margin-top: 1rem;

      &:first-child {
        margin-top: 0;
      }

      display: flex;
      flex-flow: row;

      div.pane_field {
        width: 50%;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;

        &:nth-child(1) {
          padding-right: 1rem;
        }

        &:nth-child(2) {
          // margin-right: 0;
          padding-left: 1rem;
        }

        input {
          display: block;
          width: 100%;
        }
      }
    }

    div.pane_actions {
      padding: 1.2rem 0 0.5rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      button,
      a {
        &:not(:last-child) {
          margin-right: 1.2rem;
        }
      }
    }
  }

  div.pane_noContent {
    padding: 0.75rem 0 0;
    color: theme.$text-secondary;
  }

  div.pane_placeholder {
    height: 5rem;
  }
}

// Login Box (used for login & reset password)

.loginBox {
  margin-top: calc(3rem + env(safe-area-inset-top));
  margin-left: auto;
  margin-right: auto;
  width: min(24rem, calc(100% - 2rem));
  padding: 1rem 1.8rem 1.6rem;
  box-sizing: border-box;

  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: theme.$bkgr;

  .title {
    padding-bottom: 0.35rem;
    margin-bottom: 1.25rem;
    font-size: 2rem;
    font-weight: 800;
    border-bottom: solid 1px theme.$separator;
  }

  .smallTitle {
    padding-bottom: 0.35rem;
    margin-bottom: 1.25rem;
    font-size: 1.35rem;
    font-weight: 700;
    border-bottom: solid 1px theme.$separator;
  }

  .actions {
    margin-top: 2rem;
    display: flex;
    flex-flow: row;
    align-items: center;

    button {
      padding: 0.5rem 1.5rem 0.6rem;
      font-size: 0.95rem;
      font-weight: 600;
    }
  }

  .error {
    color: theme.$text-error;
    padding-bottom: 0.8rem;
  }

  .message {
    color: theme.$main-compl;
    font-weight: 500;
    padding-bottom: 0.8rem;
  }

  input {
    width: 100%;
    padding: 0.25rem 0.6rem 0.35rem;
  }

  input ~ label {
    margin-top: 0.95rem;
  }

  @media (max-width: 575px) {
    padding-top: 1rem;
  }
}

//

.page_header {
  // width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 1rem;

  .title_large {
    flex: 1 1 auto;
    font-size: 2rem;
    font-weight: 700;
  }

  .title_small {
    flex: 1 1 auto;
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.page_backLink {
  display: inline-block;
  margin: 0.3rem 0 1rem;

  font-size: 0.9rem;
  font-weight: 600;
  color: theme.$main-darker;

  i {
    font-size: 0.85rem;
    margin-right: 0.4rem;
  }

  @media (hover: hover) {
    &:hover {
      color: color.scale(theme.$main-darker, $lightness: -50%);
    }
  }
}

.page_error {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 0.65rem;
  background-color: theme.$bkgr;
  color: theme.$text-error;
  font-weight: 500;

  i {
    margin-right: 0.35rem;
  }
}

// Buttons

button_base {
  border: none;
  outline: none;
  padding: 0;
  background-color: white;
  border-radius: 2px;

  transition: all 0.2s;
}

button.button_primary {
  @extend button_base;

  $bkgr: theme.$main-darker;
  background-color: $bkgr;
  color: white;
  padding: 0.4rem 1.2rem 0.5rem;

  @media (hover: hover) {
    &:hover:enabled {
      cursor: pointer;
      color: #f0f0f0;
      background-color: color.scale($bkgr, $lightness: -15%);
    }
  }

  &:disabled {
    cursor: default;
    background-color: color.scale($bkgr, $saturation: -25%, $lightness: +35%);
  }
}

button.button_primary_danger {
  @extend button_base;

  $bkgr: theme.$text-error;
  background-color: $bkgr;
  color: white;
  padding: 0.45rem 1.2rem 0.55rem;

  @media (hover: hover) {
    &:hover:enabled {
      cursor: pointer;
      background-color: color.scale($bkgr, $lightness: -15%);
    }
  }

  &:disabled {
    cursor: default;
    background-color: color.scale($bkgr, $saturation: -0%, $lightness: +30%);
  }
}

button.button_secondary {
  @extend button_base;

  $color: #b5b5b5;

  background-color: $color;
  color: white;
  padding: 0.45rem 1.2rem 0.55rem;

  @media (hover: hover) {
    &:hover:enabled {
      cursor: pointer;
      background-color: color.scale($color, $lightness: -15%);
    }
  }

  &:disabled {
    cursor: default;
    background-color: color.scale(color.grayscale($color), $lightness: +40%);
  }
}

button.button_plain {
  @extend button_base;

  background-color: transparent;
  color: theme.$text;
  cursor: pointer;

  &:disabled {
    color: color.scale(theme.$text, $lightness: -50%);
    cursor: default;
  }
}

button.button_plain_dimmed {
  @extend button_base;

  background-color: transparent;
  color: theme.$text-secondary;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: theme.$text;
    }
  }
}

button.button_link {
  @extend button_base;

  background-color: transparent;
  font-weight: 600;
  color: theme.$main-darker;

  @media (hover: hover) {
    &:hover:enabled {
      color: color.scale(theme.$main-darker, $lightness: 30%);
      cursor: pointer;
    }
  }

  &:disabled {
    color: color.scale(theme.$text-secondary, $lightness: -50%);
  }
}

button.button_link_danger {
  @extend button_base;

  background-color: transparent;
  font-weight: 600;
  color: theme.$text-error;

  @media (hover: hover) {
    &:hover:enabled {
      color: color.scale(theme.$text-error, $lightness: 30%);
      cursor: pointer;
    }
  }

  &:disabled {
    color: color.scale(theme.$text-secondary, $lightness: -50%);
  }
}

// Utilities

.legend {
  margin-top: 0.25rem;
  font-size: 0.8rem;
  font-style: italic;
  color: theme.$text-secondary;
}

.flexSpacer {
  flex: 1000 1000 auto;
}
