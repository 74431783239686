@use '/src/app/Theme.scss' as theme;

// Styling react-calendar
:global {
  .react-calendar {
    margin-bottom: 0.5rem;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0 0 0.4rem 0.17rem;
    border: none;
    outline: none;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: transparent;
    color: theme.$main;
    // border-radius: 50%;
    // border: solid 1px theme.$main;
    cursor: pointer;
    display: flex;
    align-items: center;
    vertical-align: center;
    justify-content: center;

    &:disabled {
      filter: grayscale(1);
      opacity: 0.4;
    }
  }

  .react-calendar__navigation__label {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 800;
    color: theme.$text;
  }

  .react-calendar__month-view__weekdays {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    background-color: theme.$bkgr;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 0.95rem;
    font-weight: 700;
    padding: 0.35rem;
    text-align: center;
  }

  button.react-calendar__tile {
    border: none;
    outline: none;
    padding: 0;

    height: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    color: theme.$text;
    margin: 0.3rem 0;
    text-align: center;

    box-sizing: border-box;

    abbr {
      display: inline-flex;
      width: 2.2rem;
      height: 2.2rem;
      padding-left: 0.05rem;
      padding-bottom: 0.1rem;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      background-color: transparent;
      // transition: background-color 0.2s;
      box-sizing: border-box;
    }

    &:disabled {
      color: theme.$text-secondary;
    }
  }

  // button.react-calendar__month-view__days__day {
  // }

  button.react-calendar__tile--now {
    // background-color: $t-inv-fill-4;
    // color: rgba(blue, 50%) !important;
    // font-weight: 600 !important;

    abbr {
      // color: $t-inv-text;
      color: theme.$text;
      font-weight: 700;
      // background-color: rgba(gray, 20%);
      // border: solid 1px rgba(gray, 50%);
      // box-sizing: border-box;
      // text-decoration: underline;
      // border-bottom: solid 2px rgba(theme.$text, 33%);
      // border-radius: 0;
      // width: 1.8rem;
      // height: 1.7rem;
    }
  }

  // button.react-calendar__tile--active {
    // background-color: blue;
  // }

  button.react-calendar__tile--rangeStart {
    abbr {
      color: white;
      background-color: theme.$main-compl;
    }
  }

  button.react-calendar__tile--rangeEnd {
    abbr {
      color: white;
      background-color: theme.$main-compl;
    }
  }
}
