import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import classNames from 'classnames';
import _ from 'lodash';

import { StoreResponse, resolveStoreCashupRecipients, resolveStoreTills } from '/src/models/Store';
import { Page, Button, Pane } from '/src/app/Elements';
import { useMemoryState } from '/src/comps/Helpers';
import { PersonResponse } from '/src/models/Person';
import TillLayoutsPage from '/src/till-layouts/TillLayoutsPage';
import { TillLayoutResponse } from '/src/models/TillLayout';

import Styles from './StoresPage.scss';

const StoresPage = () => {
  let history = useHistory();

  let [stores, setStores] = useMemoryState<StoreResponse[]>(`stores`);
  let [tillLayouts, setTillLayouts] = useMemoryState<TillLayoutResponse[]>(`tillLayouts`);
  let [persons, setPersons] = useMemoryState<PersonResponse[]>(`persons`);
  let [error, setError] = useState<string>(null);

  useEffect(() => {
    async function fetch() {
      console.log('Fetching stores')

      try {
        let response = (await Axios.get('/api/storesWithLinkedData')).data;

        console.log(`Got stores with linked data: ${JSON.stringify(response, null, 2)}`);

        let stores = _(response.stores as StoreResponse[])
          .orderBy(x => x.name.toLowerCase())
          .value();

        let tillLayouts = response.tillLayouts as TillLayoutResponse[];
        let persons = response.persons as PersonResponse[];

        ReactDOM.unstable_batchedUpdates(() => {
          setStores(stores);
          setTillLayouts(tillLayouts);
          setPersons(persons);
          setError(null);
        });

      } catch (error) {
        console.log(error);

        ReactDOM.unstable_batchedUpdates(() => {
          setStores(null);
          setPersons(null);
          setError(error.toString());
        });
      }
    }

    fetch();

  }, []);

  function handleCreateStore() {
    history.push('/store/create')
  }

  function handleView(store: StoreResponse) {
    history.push(`/store/${store.id}`, {
      store: store,
      tillLayouts: tillLayouts,
      persons: persons
    });
  }

  return (
    <div className={classNames(Styles.container)}>
      <Page.Header title='Stores'>
        <Button buttonStyle='primary'
          onClick={handleCreateStore} >
          <i className='far fa-plus fa-sm' style={{ marginRight: '0.3rem' }} />
          Add Store
        </Button>
      </Page.Header>

      {error && <Page.Error>{error}</Page.Error>}

      {/* <PageError>{'Internet not ready'}</PageError> */}

      <TransitionGroup component={null} >
        {
          (stores ?? []).map(store => {
            let recipients = resolveStoreCashupRecipients(store, persons);

            return (
              <CSSTransition key={store.id}
                in={true}
                timeout={500}
                classNames={{
                  enter: Styles.store_enter,
                  enterActive: Styles.store_enter_active
                }}>
                <Pane key={store.id} className={Styles.store}>
                  <div className={Styles.heading}>
                    <div className={Styles.name}>{store.name}</div>
                    <a onClick={() => handleView(store)}>
                      View
                      <i className='far fa-chevron-right' />
                    </a>
                  </div>
                  {/* <div>{store.login}</div> */}
                  <div>
                    {(() => {
                      let tills = resolveStoreTills(store, tillLayouts)

                      let layouts = _(tills)
                      .uniqBy(x => x.id)
                      .sortBy(x => x.name.toLowerCase())
                      .value();
                      
                      return tills.length > 0 ?
                        <>
                          <span style={{ fontWeight: 500 }}>Tills: </span>
                          <span>
                            {
                              layouts.map(layout => {
                                let count = _(tills).filter(x => x.id == layout.id).value().length;
                                return `${count} × ${layout.name}`
                              }).join(', ')
                            }
                          </span>
                        </>
                        :
                        <span className={Styles.noTills}>
                          No tills
                        </span>
                    })()}
                  </div>
                  <div>
                    {/* <i className='fal fa-at fa-fw' /> */}
                    {
                      recipients.length > 0 ?
                        <span className={Styles.recipients}>
                          <span className={Styles.at}>@</span>
                          {/* Recipients:&nbsp; */}
                          {recipients.map(x => x.name).join(', ')}
                        </span>
                        :
                        <span className={Styles.noRecipient}>
                          <i className='far fa-exclamation-circle' /> No Email Recipients
                        </span>
                    }
                  </div>
                </Pane>
              </CSSTransition>
            );
          })
        }
      </TransitionGroup>
    </div>
  );
}

export default StoresPage;