import React, { useContext, useState, useEffect } from "react";
import Axios from 'axios';
import _ from 'lodash';

export interface UserAuthState {
  role: 'user';
  userId: string;
  token: string;
}

export interface StoreAuthState {
  role: 'store';
  storeId: string;
  token: string;
}

export type AuthState = UserAuthState | StoreAuthState;

export interface UserInfo {
  id: string;
  email: string;
  name: string;
}

export interface StoreInfo {
  id: string;
  login: string;
  name: string;
}

interface Value {
  authState?: AuthState,
  userInfo?: UserInfo,
  storeInfo?: StoreInfo,
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext(null);
function useAuth() { return useContext<Value>(AuthContext); }

const AuthProvider = ({ children }) => {
  let [authState, setAuthState] = useState(getStoredAuthState());
  let [userInfo, setUserInfo] = useState<UserInfo>();
  let [storeInfo, setStoreInfo] = useState<StoreInfo>();

  // Axios
  // let authToken = authState?.token;

  // useEffect(() => {
  //   console.log(`Setting Authorization header = ${authState?.token}`);

  //   let interceptor = Axios.interceptors.request.use(config => {
  //     console.log('Applying auth header: ' + authToken);
  //     config.headers.Authorization = authToken;
  //     console.log(`Headers = ${JSON.stringify(config.headers)}`);
  //     return config;
  //   });

  //   console.log(`Applied interceptor (${interceptor})`);

  //   return () => {
  //     console.log(`Ejecting ${interceptor}`);
  //     Axios.interceptors.request.eject(interceptor);
  //   }

  //   // Axios.defaults.headers.common['authorization'] = authToken;

  // }, [authToken])

  // Listen to auth state changes
  // (user could log out from another tab, etc)
  useEffect(() => {
    function listener() {
      let storedAuthState = getStoredAuthState();

      if (!_.isEqual(storedAuthState, authState)) {
        setAuthState(storedAuthState);
      }
    }

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    }

  }, []);

  async function fetchUserInfo() {
    try {
      console.info(`Getting user info`);
      let info = (await Axios.get('/api/currentUser')).data;
      console.log(`Got user info: ${JSON.stringify(info, null, 2)}`);
      setUserInfo(info);

    } catch (error) {
      console.error(error);
    }
  }

  async function fetchStoreInfo() {
    try {
      console.info(`Getting store info`);
      let info = (await Axios.get('/api/currentStore')).data;
      console.log(`Got store info: ${JSON.stringify(info, null, 2)}`);
      setStoreInfo(info);

    } catch (error) {
      console.error(error);
    }
  }

  // Observe changes to user/store info that happen on backend
  useEffect(() => {
    if (authState?.role == 'user') {
      setStoreInfo(null);

      let events = new EventSource(`/api/currentUser/events?auth=${authState.token}`);
      events.onopen = () => fetchUserInfo();
      events.onmessage = () => fetchUserInfo();

      return () => events.close();

    } else if (authState?.role == 'store') {
      setUserInfo(null);

      let events = new EventSource(`/api/currentStore/events?auth=${authState.token}`);
      events.onopen = () => fetchStoreInfo();
      events.onmessage = () => fetchStoreInfo();

      return () => events.close();

    } else {
      // Do nothing
    }

  }, [authState]);

  async function login(username: string, password: string) {
    let body = { username, password };

    let response = await Axios.post('/api/login', body);
    let authState = response.data as AuthState;

    localStorage.setItem('authState', JSON.stringify(authState));
    setAuthState(authState);
  }

  async function logout() {
    Axios.post('/api/logout').catch();

    setTimeout(() => {
      clearStoredAuthState();
      setAuthState(null);
    }, 500);
  }

  return (
    <AuthContext.Provider value={{ authState, userInfo, storeInfo, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

// Retrieves user from local storage
function getStoredAuthState(): AuthState {
  let string = localStorage.getItem('authState');
  return JSON.parse(string);
}

function clearStoredAuthState() {
  localStorage.removeItem('authState');
}

// Apparently global interceptors can only be set before any request is made...
Axios.interceptors.request.use(config => {
  let authState = getStoredAuthState();

  if (authState) {
    config.headers['Authorization'] = authState.token;
  }

  // console.log(`Headers = ${JSON.stringify(config.headers)}`);
  return config;
});

Axios.interceptors.response.use(
  response => response,
  error => {
    let data = error.response?.data;

    // console.info(`Axios ~ error data = ${JSON.stringify(data)}`);

    if (data.code == 'INVALID_AUTH') {
      clearStoredAuthState();
      location.replace('/');
      throw error;
    }

    if (data && data.status && data.message) {
      throw data.message;

    } else {
      throw error;
    }
  }
);

export { AuthProvider, useAuth };
