import Axios from "axios";
import React, { useState, useEffect } from "react";

type BrowserType = 'browser' | 'iOS-webview';

interface AppContextValue {
  browser: BrowserType;
  version: string;
  env: Env;
  setEnv: (env: Env) => void;
  debugVersion: string;
  fetchVersion: () => Promise<void>;
  hostSystem: HostSystem;
}

interface HostSystem {
  postMessage: (message: HostSystemMessage) => void;
}

interface HostSystemMessage {
  name: string;
  parameters: any;
}

type Env = 'prod' | 'dev';

export const AppContext = React.createContext<AppContextValue>(null);

export const AppProvider = ({ children }) => {
  let [version, setVersion] = useState<string>(null);
  let [env, setEnv] = useState<Env>(process.env.NODE_ENV === 'development' ? 'dev' : 'prod');
  let [browser, setBrowser] = useState<BrowserType>('browser');
  let debugVersion = version + (env === 'prod' ? '' : '-dev');
  
  // console.log(`flashMessage = ${flashMessage}`);

  async function fetchVersion() {
    try {
      let newVersion: string = (await Axios.get('/api/webAppVersion')).data;
      console.info(`Got version: ${newVersion}`)

      if (!version) {
        setVersion(newVersion);
        return;
      }

      if (version != newVersion) {
        // Refresh
        window.location.replace('/');
      }

    } catch (error) {
      console.error(error);
    }
  }

  // Set window.app to allow code injection

  window['app'] = {
    fetchVersion: fetchVersion,

    setBrowser_iOSWebview: () => {
      setBrowser('iOS-webview');

      document.documentElement.classList.add('webview');
      document.documentElement.classList.add('iOS-webview');
    },

    // Called by native app
    didBecomeActive: () => {
      fetchVersion();
    },

    // Called by native app
    willResignActive: () => {

    }
  }

  // Host system callback

  const hostSystem = {
    postMessage: (message: any) => {
      try {
        let handler = (window as any).webkit?.messageHandlers?.genericHandler;

        if (handler && handler.postMessage) {
          handler.postMessage(message);
        }

      } catch (error) {
        console.log(`Error: ${error.toString()}`);
      }
    }
  };

  // Fetch version immediately
  useEffect(() => {
    fetchVersion();
  }, []);

  let value: AppContextValue = {
    browser,
    version,
    env,
    setEnv,
    debugVersion,
    fetchVersion,
    hostSystem
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}
