import React, { useState } from 'react';

const CurrencyInput = (props: {
  value: number;
  onChange: (value: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: any) => void;
  className?: string;
  errorClassName?: string;
  [other: string]: any;
}) => {

  let { value, onChange, onFocus, onBlur, onKeyDown, className, errorClassName, ...rest } = props;

  errorClassName = errorClassName ?? '';

  function formatAsCurrency(value: number): string {
    return Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value);
  }

  let [enteredText, setEnteredText] = useState<string>();
  let [isFocused, setFocused] = useState(false);

  let text = isFocused ? enteredText : formatAsCurrency(value);

  function handleChange(event) {
    setEnteredText(event.target.value);
  }

  function handleFocus(event) {
    let text = value != 0 ?
      Intl.NumberFormat('en-AU', { useGrouping: false, maximumFractionDigits: 2 }).format(value)
      :
      '' /* value is zero; empty text makes it easier to type on iDevice */;

    setEnteredText(text);

    setFocused(true);
    onFocus && onFocus();
  }

  function handleBlur(event) {
    let parsedValue: number;

    if (enteredText === '') {
      parsedValue = 0;

    } else {
      parsedValue = parseFloat(enteredText);
    }

    console.log(`parsedValue = ${parsedValue}`);

    if (parsedValue != null && !isNaN(parsedValue)) {
      onChange(parsedValue);
    }

    setFocused(false);
    onBlur && onBlur();
  }

  function handleKeyDown(event) {
    let key = event.key;

    console.log(`key = ${key}`);

    let isKeyValid =
      /^[0-9.-]$/.test(key) ||
      key == 'Backspace' || key == 'Tab' ||
      key == 'ArrowLeft' || key == 'ArrowRight';

    if (!isKeyValid) {
      // console.log('Prevented');
      event.preventDefault();
      return;
    }

    onKeyDown && onKeyDown(event);

    return true;
  }

  let parsedValue = parseFloat(text);
  let isValid = parsedValue !== null && !isNaN(parsedValue);

  return (
    <input type='text'
      inputMode='numeric'
      value={text}
      onChange={e => setEnteredText(e.target.value)}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={(className ?? '') + ' ' + (isValid ? '' : errorClassName)}
      {...rest}
    />
  )
}

export default CurrencyInput;