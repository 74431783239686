@use 'sass:color';

// $main: #679B9B;
// $main: #E4714D;
$main: #E4714D;
$main-darker: color.scale($main, $lightness: -5%);
$main-2: #A35137;
$main-compl: #159674;
$main-green: #087d5e;
$text: #292929;
$text-secondary: #757575;
$text-error: #FF5469;
$text-green: #087d5e;
$bkgr: #F9F9F9;
$bkgr-info: #a99e6c;
$separator: #D6D6D6;
$fill: #909090;

$navbar-height: 2.8rem;

// Exports
@value text: $text;
@value bkgr: $bkgr;

:export {
  mainColor: $main;
  mainDarkerColor: $main-darker;
  textColor: $text;
  textSecondaryColor: $text-secondary; 
}