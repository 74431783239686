import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Axios from 'axios';
import _ from 'lodash';

import { TillLayoutResponse } from '/src/models/TillLayout';
import { Page, Button, Pane } from '/src/app/Elements';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useMemoryState } from '/src/comps/Helpers';

import Styles from './TillLayoutsPage.scss';

const TillLayoutsPage = () => {
  let history = useHistory();

  let [layouts, setLayouts] = useMemoryState<TillLayoutResponse[]>(`tillLayouts`);
  let [error, setError] = useState<string>(null);

  useEffect(() => {
    async function fetch() {
      console.log('Fetching till layouts')

      try {
        let response = await Axios.get('/api/tillLayouts');
        let layouts = response.data as TillLayoutResponse[];

        // console.log(`Got till layouts: ${JSON.stringify(layouts)}`);

        layouts = _.orderBy(layouts, layout => layout.name.toUpperCase());

        setLayouts(layouts);
        setError(null)

      } catch (error) {
        console.log(error);
        setLayouts(null);
        setError(error.toString());
      }
    }

    fetch();

  }, []);

  function handleCreateLayout() {
    history.push('/tillLayout/create')
  }

  function handleEdit(layout: TillLayoutResponse) {
    history.push(`/tillLayout/${layout.id}`, {
      tillLayout: layout
    });
  }

  return (
    <div className={Styles.container}>
      <Page.Header title='Till Layouts'>
        <Button buttonStyle='primary'
          onClick={handleCreateLayout}
        >
          <i className='far fa-plus fa-sm' style={{ marginRight: '0.3rem' }} />
          Add Till Layout
        </Button>
      </Page.Header>

      {error && <Page.Error>{error}</Page.Error>}

      <TransitionGroup component={null} >
        {
          (layouts ?? []).map((layout, index) => {
            return (
              <CSSTransition key={layout.id}
                timeout={200}
                classNames={{
                  enter: Styles.tillLayout_enter,
                  enterActive: Styles.tillLayout_enter_active
                }}
              >
                <Pane className={Styles.tillLayout}>
                  <div className={Styles.heading}>
                    <div className={Styles.name}>
                      {layout.name}
                    </div>
                    <a onClick={e => handleEdit(layout)}>
                      <i className='far fa-pen'
                        style={{ fontSize: '0.8rem', marginRight: '0.23rem' }}
                      />
                      Edit
                    </a>
                  </div>

                  <div className={Styles.section}>
                    <span>Actual Sales</span>
                    <div className={Styles.fields}>
                      {
                        layout.actualFields.map(field => field.name).join(', ')
                      }
                    </div>
                  </div>

                  <div className={Styles.section}>
                    <span>Expected Sales</span>
                    <div className={Styles.fields}>
                      {
                        layout.expectedFields.map(field => field.name).join(', ')
                      }
                    </div>
                  </div>

                </Pane>
              </CSSTransition>
            );
          })
        }
      </TransitionGroup>
    </div>
  );
}

export default TillLayoutsPage;