@use '/src/App/Theme.scss' as theme;

ul.container {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    // font-size: 0.9rem;
    display: block;
    padding: 0.4rem 1rem 0.6rem;
    color: theme.$text;
    font-weight: normal;

    // transition: all 0.1s;
    cursor: pointer;

    &:not(:first-child) {
      border-top: solid 1px rgba(theme.$separator, 50%);
    }

    i {
      margin-right: 0.4rem;
      width: 1rem;
      color: theme.$text-secondary;
    }

    @media (hover: hover) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.14);
      }
    }
  }
}
