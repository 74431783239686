@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

.person {
  padding: 0.7rem 1.2rem;
  
  .heading {
    display: flex;
    flex-flow: row;

    .name {
      flex: 1 1 auto;
      font-weight: 700;
    }

    a {
      flex: 0 0 auto;
      font-size: 0.9rem;

      i {
        margin-right: 0.3rem;
        font-size: 0.75rem;
      }
    }
  }
}

.person_enter {
  transition: all 150ms;
  opacity: 0;
  transform: translateX(5px);
}

.person_enter_active {
  opacity: 1;
  transform: none;
}