// Store returned by backend

import { PersonResponse } from "./Person";
import _ from "lodash";
import { TillLayoutResponse } from "./TillLayout";

export interface StoreResponse {
  id: string;
  name: string;
  login: string;
  tills: StoreTill[]; // Array of till layouts
  cashupRecipients: StoreCashupRecipient[];
}

export interface StoreTill {
  layoutId: string;
}

export interface StoreCashupRecipient {
  personId: string;
}

export function resolveStoreTills(store: StoreResponse, tillLayouts: TillLayoutResponse[]) {
  return store.tills
    .map(till => tillLayouts.find(x => x.id == till.layoutId))
    .filter(x => x !== null)
}

export function resolveStoreCashupRecipients(store: StoreResponse, persons: PersonResponse[]) {
  console.log(`store = ${store}`);
  console.log(`store = ${JSON.stringify(store, null, 4)}`);

  const l = _(store.cashupRecipients)
    .map(recipient => persons.find(x => x.id == recipient.personId));

  console.log(`l = ${l}`);
  console.log(`l = ${JSON.stringify(l, null, 4)}`);
  
  return l
    .filter(x => x != null)
    .sortBy(x => x.name.toLowerCase())
    .value();
}