@use 'sass:color';
@use '/src/app/Theme.scss' as theme;

.container {
  .layoutInset {
    padding-top: env(safe-area-inset-top);
    height: theme.$navbar-height;
    box-sizing: content-box;
  }

  .fixer {
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    padding-top: env(safe-area-inset-top);
    height: theme.$navbar-height;
    box-sizing: content-box;

    z-index: 100;
    background-color: #f0f0f0;

    // box-shadow: 0 0px 4px 5px rgba(black, 7%);
    border-bottom: solid 1px rgba(black, 7%);

    @supports ((-webkit-backdrop-filter: blur(100px)) or (backdrop-filter: blur(100px))) {
      background-color: rgba(#f0f0f0, 50%);
      backdrop-filter: blur(100px);
      -webkit-backdrop-filter: blur(100px);
    }
  }
}

.content {
  height: 100%;
  display: flex;
  padding: 0.0rem 1.25rem;
  align-items: center;

  .logo {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }

  .logo-alt {
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.2rem 0.2rem 0.25rem 0.35rem;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 1px black;
    margin-right: 0.5rem;
    background-color: black;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: -0.025rem;
    color: theme.$text;
  }

  button {
    font-weight: 600;
    color: color.scale(theme.$text-secondary, $lightness: -20%);
    // color: theme.$main-darker;

    i {
      margin-left: 0.45rem;
    }

    padding: 0.3rem 0;
  }
}
