import React, { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

// Modal Context

interface ModalContextValue {
  component: React.ReactNode;
  presented: boolean;
  show: (component: React.ReactNode) => Promise<void>;
  dismiss: () => Promise<void>;
}

const ModalContext = React.createContext<ModalContextValue>(null);

// Modal is a global API, thus a global state
// Ran into a problem with show/dismiss:
// Since show/dimiss captures current React states, they cannot be used consecutively in the same event loop, and the state has to be kept locally
const modalState = {
  component: null as React.ReactNode,
  presented: false,
};

export const ModalProvider = ({ children }) => {
  let [component, _setComponent] = useState<React.ReactNode>(null);
  let [presented, _setPresented] = useState<boolean>(false);

  function setComponent(component: React.ReactNode) {
    _setComponent(component);
    modalState.component = component;
  }

  function setPresented(presented: boolean) {
    _setPresented(presented);
    modalState.presented = presented;
  }

  // console.info(`ModalProvider function called, component = ${component}, presented = ${presented}`);

  // console.info(`Rand = ${ModalState.rand}`);

  async function show(comp: React.ReactNode) {
    // console.log(`Modal ~ show, comps = ${component?.toString()}, presented = ${presented}`);
    // console.info(`modalState = ${JSON.stringify(modalState)}`);

    if (modalState.presented) {
      console.error(`Modal: trying to show, while already presenting another component`);
    }

    setComponent(comp);
    setPresented(true);
  }

  async function dismiss(): Promise<void> {
    // console.log(`Modal ~ dismiss, comps = ${component?.toString()}, presented = ${presented}`);
    // console.info(`modalState = ${JSON.stringify(modalState)}`);

    setPresented(false);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (modalState.component === component) {
          setComponent(null);
        }

        resolve();
      }, 200);
    });

    // console.info(`Modal ~ dismiss, set timeout = ${modalState.timeoutId}`);
  }

  return (
    <ModalContext.Provider value={{ component, show, presented, dismiss }}>
      {children}
    </ModalContext.Provider>
  )
}

export function useModalContext() { return useContext(ModalContext); }

// Modal Renderer

const Styles = require('./Modal.scss');

export const ModalRenderer = () => {
  let modalContext = useModalContext();

  return (
    <CSSTransition
      in={modalContext.presented}
      timeout={150}
      classNames={{
        enter: Styles.modalWindow_enter,
        enterActive: Styles.modalWindow_enter_active,
        enterDone: Styles.modalWindow_enter_done,
        exit: Styles.modalWindow_exit,
        exitActive: Styles.modalWindow_exit_active,
        exitDone: Styles.modalWindow_exit_done
      }}
      unmountOnExit>
      <div>
        {modalContext.component}
      </div>
    </CSSTransition>
  );
}

// Modal & its components

type ModalSize = 'regular' | 'small';

export function Modal({ modalSize, className, ...rest }:
  { modalSize: ModalSize, className?: string, [other: string]: any }
) {
  let cls = modalSize == 'regular' ? Styles.modal : Styles.modal_small;

  return (
    <div className={classNames(cls, className)} {...rest} />
  );
}

export namespace Modal {
  export const Heading = (props: {
    title: string;
    [other: string]: any;
  }) => {
    let { title, className, children, ...rest } = props;

    return (
      <div className={classNames(Styles.heading, className)}>
        <div className={Styles.title}>
          {title}
        </div>
        {children}
      </div>
    );
  }

  export const CloseButton = () => {
    let modalContext = useModalContext();

    return (
      <button className={Styles.close} onClick={() => modalContext.dismiss()}>
        <i className='fas fa-times' />
      </button>
    )
  }

  export const Actions = ({ className, ...rest }: any) => {
    return (
      <div className={classNames(Styles.actions, className)} {...rest} />
    )
  }
}