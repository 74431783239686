import React from 'react';
import classNames from 'classnames';
import { useModalContext, Modal } from '/src/app/Modal';
import { Button } from '/src/app/Elements';

import Styles from './DialogModal.scss';

interface Action {
  name: any,
  style: 'neutral' | 'ok' | 'danger',
  autoDismiss?: boolean;
  handler?: () => void
}

const DialogModal = (props: {
  title?: string;
  message: any;
  actions?: Array<Action | 'cancel' | 'close'>;
}) => {
  let modalContext = useModalContext();
  // console.log(`modal = ${JSON.stringify(modal)}`);

  let actions: Action[] = (props.actions ?? ['close']).map(action => {
    switch (action) {
      case 'cancel': return { name: 'Cancel', style: 'neutral' };
      case 'close': return { name: 'Close', style: 'neutral' };
      default: return action;
    }
  });

  return (
    <Modal modalSize='small' className={Styles.container}>
      <Modal.Heading title={props.title} />

      <div className={Styles.message}>{props.message}</div>

      <Modal.Actions>
        {
          actions.map((action, index) =>
            <Button
              key={index}
              buttonStyle={(() => {
                switch (action.style) {
                  case 'ok': return 'primary';
                  case 'danger': return 'primary-danger';
                  case 'neutral': return 'secondary';
                }
              })()}
              style={{
                fontWeight: action.style == 'ok' || action.style == 'danger' ? 600 : 500,
                paddingLeft: '1.2rem',
                paddingRight: '1.2rem'
              }}
              onClick={async () => {
                if (action.autoDismiss ?? true) {
                  modalContext.dismiss();
                }

                action.handler?.();
              }}>
              {action.name}
            </Button>
          )
        }

      </Modal.Actions>
    </Modal>
  )
}

export default DialogModal;