@use 'sass:color';
@use '/src/App/Theme.scss' as theme;

.page {
  display: flex;
  flex-flow: row nowrap;
  padding: 1.4rem 1.2rem;

  .content {
    flex: 1 1 auto;
  }
}

.flashContainer {
  display: flex;
  justify-content: center;

  .flash {
    padding: 0.25rem 5rem 0.35rem;
    margin: 1.25rem 0 0.25rem;
    text-align: center;
    color: color.scale(theme.$main-compl, $lightness: -15%);
    font-size: 0.95rem;
    border: solid 1px color.scale(theme.$main-compl, $lightness: 0%);
    border-radius: 3px;
  }  
}
