import React, { useState } from 'react';
import { startOfDay, addDays } from 'date-fns';
import Calendar from 'react-calendar';

import { useModalContext, Modal } from '/src/app/Modal';
import { Button } from '/src/app/Elements';

const S = {
  me: require('./DatePickerModal.scss'),
  modal: require('/src/App/Modal.scss')
}

const DatePickerModal = (props: {
  title: any;
  date: Date;
  minDate: Date;
  onCommit: (date: Date) => void;
}) => {
  let modal = useModalContext();

  let [date, setDate] = useState(props.date);

  return (
    <Modal modalSize='regular'>
      <Calendar
        maxDetail='month'
        minDetail='month'
        minDate={props.minDate}
        value={date}
        onChange={date => {
          console.log('Setting date to ' + JSON.stringify(date));
          setDate(date as Date);
        }}
      />

      <Modal.Actions>
        <Button buttonStyle='primary'
          style={{ fontWeight: 600 }}
          disabled={!date}
          onClick={() => {
            modal.dismiss();
            props.onCommit(date);
          }}>
          Done
        </Button>
        <Button buttonStyle='secondary'
          onClick={() => modal.dismiss()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DatePickerModal;