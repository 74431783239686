@use '/src/App/Theme.scss' as theme;

.till {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.6rem 1rem 0.65rem;
  background-color: white;
  border: solid 1px theme.$separator;

  margin-top: 0.65rem;

  &:first-child {
    margin-top: 0.9rem;
  }

  &:last-child {
    margin-bottom: 0.6rem;
  }

  .index {
    width: 5rem;
    color: theme.$text-secondary;
    text-transform: uppercase;
  }

  .layout {
    flex: 1 1 auto;

    span.name {
      font-weight: 600;
    }
  }

  .actions {
    margin-left: 1rem;

    button {
      font-size: 0.9rem;
      font-weight: normal;
    }
  }
}

.person {
  display: flex;
  align-items: baseline;

  &:first-child {
    padding-top: 0.5rem;
  }

  padding-bottom: 0.1rem;

  cursor: pointer;

  input[type="checkbox"] {
    margin-right: 0.5rem;
    display: inline;
    cursor: pointer;
    transform: translateY(0.05rem);
  }

  label {
    text-transform: none;
    display: inline;
    cursor: pointer;
  }
}

.enter {
  transition: all 0.15s;
  opacity: 0;
}

.enterActive {
  opacity: 1;
}
