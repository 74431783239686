.container {
  display: inline-block;
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: -10px;
  min-width: 150px;
  border: solid 1px rgba(black, 0.08);
  box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  opacity: 1;
  transform: none;
  background-color: white;
  transform-origin: top right;
}

.dropdown_enter {
  composes: dropdown;
  opacity: 0;
  transform: scale(0.9, 0.9);
} 

.dropdown_enter_active, .dropdown_enter_done {
  composes: dropdown;
  opacity: 1;
  transform: none;
  transition: all 0.1s;
}

.dropdown_exit {
  composes: dropdown;
}

.dropdown_exit_active, .dropdown_exit_done {
  composes: dropdown;
  opacity: 0;
  transform: scale(0.9, 0.9);
  transition: all 0.1s;
}