import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router";
import Axios from "axios";

import { Page, Pane, FlexSpacer, Button } from "/src/app/Elements";
import { FlashContext } from "/src/app/FlashContext";
import { PersonResponse } from "/src/models/Person";
import { useModalContext } from "/src/app/Modal";
import DialogModal from "/src/comps/DialogModal";

const EditPersonPage = () => {
  let modal = useModalContext();
  let flashContext = useContext(FlashContext);

  let params = useParams<{ id: string }>();
  let personId = params.id;

  let location = useLocation<{ person: PersonResponse }>();

  let history = useHistory();

  let [person, setPerson] = useState<PersonResponse>(location.state?.person);
  let [name, setName] = useState<string>(location.state?.person?.name);
  let [email, setEmail] = useState<string>(location.state?.person.email);
  let [changed, setChanged] = useState<boolean>(false);

  let [saving, setSaving] = useState<boolean>(false);
  let [error, setError] = useState<string>(null);

  async function fetch() {
    try {
      let response = await Axios.get(`/api/person/${personId}`);
      let person = response.data as PersonResponse;

      console.log(`Got person: ${JSON.stringify(person, null, 2)}`);

      setPerson(person);
      setName(person.name);
      setEmail(person.email);
      setError(null);

    } catch (error) {
      console.error(error);

      setError(error.toString());
    }
  }

  useEffect(() => {
    if (personId) {
      fetch();
    }

  }, []);

  async function handleSave() {
    setError(null);
    setSaving(true);

    if (personId) {
      try {
        let body = { name, email };

        await Axios.post(`/api/person/${personId}`, body);

        flashContext.setMessage('Person updated');
        history.push('/persons');

      } catch (error) {
        console.error(error);
        setError(error.toString());

      } finally {
        setSaving(false);
      }

    } else {
      try {
        let body = { name, email };

        await Axios.put(`/api/person`, body);

        flashContext.setMessage('Person added');
        history.push('/persons');

      } catch (error) {
        console.error(error);
        setError(error.toString());

      } finally {
        setSaving(false);
      }
    }
  }

  function handleDelete() {
    modal.show(
      <DialogModal
        title='Confirm'
        message={
          <span>Delete <b>{person.name}</b>? This cannot be undone.</span>
        }
        actions={[
          {
            name: 'Delete',
            style: 'danger',
            handler: async () => {
              try {
                await Axios.delete(`/api/person/${personId}`);

                flashContext.setMessage('Person deleted');
                history.push('/persons');

              } catch (error) {
                console.log(error);
                setError(error.toString());
              }
            }
          },
          'cancel'
        ]}
      />
    );
  }

  return (
    <div>
      <Page.BackLink to='/persons'>Back To People</Page.BackLink>

      <Page.Header title={personId ? 'Edit Person' : 'Add Person'} />

      {error && <Page.Error>{error}</Page.Error>}

      <Pane>
        <Pane.Header>Person Info</Pane.Header>

        <Pane.Separator />

        {
          (!personId || person) ?
            <Pane.Content>
              <Pane.Content.FieldRow>
                <Pane.Content.Field>
                  <label>Name</label>
                  <input type='text'
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                      setChanged(true);
                    }} />
                </Pane.Content.Field>
                <Pane.Content.Field>
                  <label>Email</label>
                  <input type='text'
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setChanged(true);
                    }} />
                </Pane.Content.Field>
              </Pane.Content.FieldRow>

              <Pane.Content.Actions style={{ marginTop: '2rem' }}>
                {
                  personId != null &&
                  <Button buttonStyle='link-danger'
                    onClick={handleDelete}>
                    <i className='far fa-trash-alt' style={{ marginRight: '0.33rem' }} />
                    Delete
                  </Button>
                }
                <FlexSpacer />
                {
                  personId == null &&
                  <Button buttonStyle='secondary'
                    onClick={() => history.push(`/persons`)}>
                    Cancel
                  </Button>
                }
                <Button buttonStyle='primary'
                  disabled={!changed || !name || !email || saving}
                  onClick={handleSave}>
                  {personId ? 'Save Changes' : 'Add Person'}
                </Button>
              </Pane.Content.Actions>
            </Pane.Content>
            :
            <div style={{ height: '8rem' }} />
        }
      </Pane>
    </div>
  );
}

export default EditPersonPage;