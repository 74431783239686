import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import Axios from 'axios';
import classNames from 'classnames';

import { FlashContext } from '/src/app/FlashContext';
import { Page, Pane, FlexSpacer, Legend, Button } from '/src/app/Elements';
import { StoreResponse } from '/src/models/Store';

import Styles from './EditStorePage.scss';

const EditStorePage = () => {
  let flashContext = useContext(FlashContext);

  let params = useParams<{ id: string }>();
  let storeId = params.id;

  let location = useLocation<{store: StoreResponse}>();

  let history = useHistory();

  let [store, setStore] = useState<StoreResponse>(location.state?.store);
  let [name, setName] = useState<string>(location.state?.store?.name ?? '');
  let [login, setLogin] = useState<string>(location.state?.store?.name ?? '');
  let [password, setPassword] = useState<string>('');
  let [changed, setChanged] = useState<boolean>(false);

  let [saving, setSaving] = useState<boolean>(false);
  let [error, setError] = useState<string>(null);

  async function fetch() {
    try {
      let store: StoreResponse = (await Axios.get(`/api/store/${storeId}`)).data;

      setStore(store);
      setName(store.name);
      setLogin(store.login);
      setPassword('');

    } catch (error) {
      console.log(error);

      setError(error.toString());
    }
  }

  useEffect(() => {
    if (storeId) {
      fetch();
    }

  }, []);

  async function handleSave() {
    setError(null);
    setSaving(true);

    if (storeId) {
      try {
        let body = { name, login, password };

        await Axios.post(`/api/store/${storeId}`, body);

        flashContext.setMessage('Store updated');
        history.push('/stores');

      } catch (error) {
        console.log(error);
        setError(error.toString());

      } finally {
        setSaving(false);
      }

    } else {
      try {
        let body = { name, login, password };

        await Axios.put('/api/store', body);

        flashContext.setMessage('Store added');
        history.push('/stores');

      } catch (error) {
        console.log(error);
        setError(error.toString());

      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <div className={classNames(Styles.container)}>
      {
        storeId ?
          <Page.BackLink to={`/store/${storeId}`}>Back To Store</Page.BackLink>
          :
          <Page.BackLink to='/stores'>Back To Stores</Page.BackLink>
      }

      <Page.Header title={storeId ? 'Edit Store' : 'Add Store'} />

      {error && <Page.Error>{error}</Page.Error>}

      <Pane>
        <Pane.Header>Store Details</Pane.Header>

        <Pane.Separator />

        {
          (!storeId || store) ?
            <Pane.Content>
              <Pane.Content.FieldRow>
                <Pane.Content.Field>
                  <label>Name</label>
                  <input type='text'
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                      setChanged(true);
                    }} />
                </Pane.Content.Field>
                <Pane.Content.Field>
                  <label>Email</label>
                  <input type='text'
                    value={login}
                    onChange={e => {
                      setLogin(e.target.value);
                      setChanged(true);
                    }}
                    autoComplete='off_login'
                  />
                </Pane.Content.Field>
              </Pane.Content.FieldRow>

              <Pane.Content.FieldRow>
                {/* Spacer */}
                <Pane.Content.Field />
                <Pane.Content.Field>
                  <label>Password</label>
                  <input type='text'
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                      setChanged(true);
                    }}
                    autoComplete='off'
                  />
                  {
                    storeId &&
                    <Legend>*Leave blank to not update</Legend>
                  }
                </Pane.Content.Field>
              </Pane.Content.FieldRow>

              <Pane.Content.Actions style={{ marginTop: '2rem' }}>
                <FlexSpacer />
                {
                  storeId == null &&
                  <Button buttonStyle='secondary'
                    onClick={() => history.push(`/stores`)}>
                    Cancel
                  </Button>
                }
                <Button buttonStyle='primary'
                  disabled={!changed || !name || !login || (storeId == null && !password) || saving}
                  onClick={handleSave}>
                  {storeId ? 'Save Changes' : 'Add Store'}
                </Button>
              </Pane.Content.Actions>
            </Pane.Content>
            :
            <div style={{ height: '8rem' }} />
        }

      </Pane>
    </div>
  );
}

export default EditStorePage;