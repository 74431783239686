import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Axios from 'axios';

import '/src/../libs/normalize.css/normalize.css';
import '/src/../libs/fontawesome/css/all.css'

import './Theme.scss';
import './Reset.scss';

import LoginPage from '/src/login/LoginPage';

import { AuthProvider, useAuth } from './AuthContext';
import { AppProvider, AppContext } from './AppContext';
import { ModalProvider, ModalRenderer } from './Modal';
import { FlashProvider, FlashContext } from './FlashContext';
import ScrollToTop from '/src/comps/ScrollToTop';
import DismissModal from '/src/comps/DismissModal';

import NavBar from '/src/app/NavBar';
import SideMenu from '/src/app/SideMenu';
import ProfilePage from '/src/profile/ProfilePage';
import StoresPage from '/src/stores/StoresPage';
import StorePage from '/src/stores/StorePage';
import EditStorePage from '/src/stores/EditStorePage';
import TillLayoutsPage from '/src/till-layouts/TillLayoutsPage';
import TillLayoutPage from '/src/till-layouts/TillLayoutPage';
import StoreDashboardPage from '/src/store/StoreDashboardPage';
import StoreCashUpPage from '/src/store/StoreCashUpPage';
import ResetPasswordPage from '/src/login/ResetPasswordPage';
import { Layout } from '/src/app/Elements';
import PersonsPage from '/src/persons/PersonsPage';
import EditPersonPage from '/src/persons/EditPersonPage';

const Styles = require('./App.scss');

const App = () => {
  let appContext = useContext(AppContext);
  let auth = useAuth();

  useEffect(() => {
    // console.log('Got called...');

    // Version not fetched?
    if (!appContext.version) { return; }

    // Handle version change
    let oldVersion = localStorage.getItem('version');
    console.log(`old version = '${oldVersion}'; app version = '${appContext.version}'`);

    if (oldVersion && oldVersion != appContext.version) {
      // modal.show(
      // <DialogModal title='App Updated' message={`${app.version}`} />
      // );
    }

    localStorage.setItem('version', appContext.version);

  }, [appContext.version]);


  // useEffect(() => {
  //   // Handle redirect message

  //   let title = Cookies.get('redirect_title')
  //   let msg = Cookies.get('redirect_message');

  //   if (title || msg) {
  //     Cookies.remove('redirect_title');
  //     Cookies.remove('redirect_message');

  //     modal.show(
  //       <DialogModal title={title} message={msg} />
  //     );
  //   }

  // }, []);

  return (
    <React.Fragment>
      <div id='root'>
        <BrowserRouter>
          <FlashProvider>
            <ScrollToTop />
            <DismissModal />
            <Switch>
              <Route exact path='/login'>
                {
                  auth.authState ?
                    <Redirect to='/' /> :
                    <LoginPage />
                }
              </Route>
              <Route exact path='/resetPassword'>
                <ResetPasswordPage />
              </Route>
              <Route exact path='/store'>
                <PageContainer requiredAuth='store' sideMenu={false} wide={false}>
                  <StoreDashboardPage />
                </PageContainer>
              </Route>
              <Route exact path='/store/newCashUp'>
                <PageContainer requiredAuth='store' sideMenu={false} wide={false}>
                  <StoreCashUpPage />
                </PageContainer>
              </Route>
              <Route exact path='/store/newCashUp'>
                <PageContainer requiredAuth='store' sideMenu={false} wide={false}>
                  <StoreDashboardPage />
                </PageContainer>
              </Route>
              <Route exact path='/profile'>
                <PageContainer requiredAuth='user'>
                  <ProfilePage />
                </PageContainer>
              </Route>
              <Route exact path='/persons'>
                <PageContainer requiredAuth='user'>
                  <PersonsPage />
                </PageContainer>
              </Route>
              <Route exact path='/person/create'>
                <PageContainer requiredAuth='user'>
                  <EditPersonPage />
                </PageContainer>
              </Route>
              <Route exact path='/person/:id'>
                <PageContainer requiredAuth='user'>
                  <EditPersonPage />
                </PageContainer>
              </Route>
              <Route exact path='/stores'>
                <PageContainer requiredAuth='user'>
                  <StoresPage />
                </PageContainer>
              </Route>
              <Route exact path='/store/create'>
                <PageContainer requiredAuth='user'>
                  <EditStorePage />
                </PageContainer>
              </Route>
              <Route exact path='/store/:id'>
                <PageContainer requiredAuth='user'>
                  <StorePage />
                </PageContainer>
              </Route>
              <Route exact path='/store/:id/edit'>
                <PageContainer requiredAuth='user'>
                  <EditStorePage />
                </PageContainer>
              </Route>
              <Route exact path='/tillLayouts'>
                <PageContainer requiredAuth='user'>
                  <TillLayoutsPage />
                </PageContainer>
              </Route>
              <Route exact path='/tillLayout/create'>
                <PageContainer requiredAuth='user'>
                  <TillLayoutPage />
                </PageContainer>
              </Route>
              <Route exact path='/tillLayout/:id'>
                <PageContainer requiredAuth='user'>
                  <TillLayoutPage />
                </PageContainer>
              </Route>
              <Route> {/* Default */}
                {
                  (() => {
                    switch (auth.authState?.role) {
                      case 'user': return <Redirect to='/profile' />;
                      case 'store': return <Redirect to='/store' />;
                      default: return <Redirect to='/login' />;
                    }
                  })()
                }
              </Route>
            </Switch>
          </FlashProvider>
        </BrowserRouter>
      </div>

      <ModalRenderer />

    </React.Fragment>
  );
}

// Checks auth & adds nav bar, side menu
const PageContainer = (props: {
  children: React.ReactNode;
  requiredAuth: null | 'store' | 'user';
  sideMenu?: boolean;
  navBar?: boolean;
  wide?: boolean;
}) => {
  let flashContext = useContext(FlashContext);
  let auth = useAuth();

  // console.log(`flashCounter = ${flashCounter}`);

  switch (props.requiredAuth) {
    case null:
      break;

    case 'store':
      if (auth.authState?.role != 'store') { return <Redirect to='/' />; }
      break;

    case 'user':
      if (auth.authState?.role != 'user') { return <Redirect to='/' />; }
      break;
  }

  // console.log(`Flash = ${app.flashMessage}`);

  return (
    <React.Fragment>
      {(props.navBar ?? true) && <NavBar wide={true} />}

      {/* <div className={Styles.flashContainer}>
        <div className={Styles.flash}>Something has been updated</div>
      </div> */}

      {
        flashContext.message &&
        <div className={Styles.flashContainer}>
          <div className={Styles.flash}>{flashContext.message}</div>
        </div>
      }

      <Layout.Center width={'wide'}>
        <div className={Styles.page}>
          {(props.sideMenu ?? true) && <SideMenu />}
          <div className={Styles.content}>
            {props.children}
          </div>
        </div>
      </Layout.Center>
    </React.Fragment>
  );
}

ReactDOM.render(
  <AppProvider>
    <AuthProvider>
      <ModalProvider>
        <App />
      </ModalProvider>
    </AuthProvider>
  </AppProvider>,
  document.getElementById("app")
);

// Handle HTTP errors
Axios.interceptors.response.use(
  response => response,
  error => {
    // This causes trouble when logging in...
    // if (Cookies.get('auth') == null) {
    //   location.replace('/');
    //   return;
    // }

    let data = error.response?.data;

    if (data && data.status && data.message) {
      throw data.message;

    } else {
      throw error;
    }
  }
);