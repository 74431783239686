import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Styles = require('./Elements.scss');

/*
 A React component that includes a div with className set to a given value
*/
function styledComponent(styleClassName: string): (props: any) => JSX.Element {
  return (props: any) => styledComponent_body(styleClassName, props);
}

function styledComponent_body(styleClassName: string, props: any): JSX.Element {
  let { className, ...rest } = props;
  return <div className={classNames(styleClassName, className)} {...rest} />;
}

// Pane

export function Pane(props: any) {
  return styledComponent_body(Styles.pane, props);
}

export namespace Pane { // Declaration merging
  export const Header = styledComponent(Styles.pane_header);
  export const Separator = styledComponent(Styles.pane_separator);
  export const NoContent = styledComponent(Styles.pane_noContent);

  export function Content(props: any) {
    return styledComponent_body(Styles.pane_content, props);
  }
}

export namespace Pane.Content {
  export const FieldRow = styledComponent(Styles.pane_fieldRow);
  export const Field = styledComponent(Styles.pane_field);
  export const Actions = styledComponent(Styles.pane_actions);
}

//

export function LoginBox(props: any) {
  return styledComponent_body(Styles.loginBox, props);
}

export namespace LoginBox {
  export const Title = styledComponent(Styles.title);
  export const SmallTitle = styledComponent(Styles.smallTitle);
  export const Message = styledComponent(Styles.message);
  export const Error = styledComponent(Styles.error);
  export const Actions = styledComponent(Styles.actions);
}

// Page

export namespace Page {
  // https://mariusschulz.com/blog/typing-destructured-object-parameters-in-typescript
  export const Header = ({ title, titleStyle = 'large', className, children, ...rest }:
    {
      title?: string;
      titleStyle?: 'large' | 'small';
      [other: string]: any;
    }
  ) => {
    return (
      <div className={classNames(Styles.page_header, className)} {...rest}>
        <div className={titleStyle == 'large' ? Styles.title_large : Styles.title_small}>
          {title}
        </div>
        {children}
      </div>
    );
  }

  export const BackLink = ({ to, className, children, ...rest }: { to?: any, [other: string]: any }) => {
    return (
      <Link className={classNames(Styles.page_backLink, className)} to={to} {...rest}>
        <i className='fas fa-arrow-left' />
        {children}
      </Link>
    );
  }

  export const Error = ({ className, children, ...rest }: any) => {
    return (
      <div className={classNames(Styles.page_error, className)} {...rest}>
        <i className='fas fa-exclamation-circle' />
        {children}
      </div>
    );
  }
}

// Buttons

type ButtonStyle = 'primary' | 'primary-danger' | 'secondary' | 'plain' | 'plain-dimmed' | 'link' | 'link-danger';

interface ButtonAttributes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle: ButtonStyle;
}

export const Button = ({ buttonStyle, className, ...rest }: ButtonAttributes) => {
  let cls = (() => {
    switch (buttonStyle) {
      case 'primary': return Styles.button_primary;
      case 'primary-danger': return Styles.button_primary_danger;
      case 'secondary': return Styles.button_secondary;
      case 'plain': return Styles.button_plain;
      case 'plain-dimmed': return Styles.button_plain_dimmed;
      case 'link': return Styles.button_link;
      case 'link-danger': return Styles.button_link_danger;
    }
  })();

  return <button className={classNames(cls, className)} {...rest} />;
}

// Layout

export namespace Layout {
  export const Center = styledComponent(Styles.layout_center);
}

// Other

export const Legend = styledComponent(Styles.legend);
export const FlexSpacer = styledComponent(Styles.flexSpacer);