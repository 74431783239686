import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import classNames from 'classnames';

import DropdownButton from '/src/comps/DropdownButton';
import DropdownMenu from '/src/comps/DropdownMenu';
import { Page, Pane, Button } from '/src/app/Elements';
import { TillLayoutResponse } from '/src/models/TillLayout';
import { StoreResponse, StoreTill, StoreCashupRecipient } from '/src/models/Store';
import { useModalContext } from '/src/app/Modal';
import { FlashContext } from '/src/app/FlashContext';
import DialogModal from '/src/comps/DialogModal';
import { PersonResponse } from '/src/models/Person';
import ReactDOM from 'react-dom';

const Styles = require('./StorePage.scss');
const ElementStyles = require('/src/app/Elements.scss');

const StorePage = () => {
  let flashContext = useContext(FlashContext);
  let history = useHistory();

  let params = useParams<{ id: string }>();
  let storeId = params.id;

  let location = useLocation<{ store: StoreResponse, tillLayouts: TillLayoutResponse[], persons: PersonResponse[] }>();

  let modal = useModalContext();

  let [store, setStore] = useState<StoreResponse>(location.state?.store);
  let [tills, setTills] = useState<StoreTill[]>(location.state?.store?.tills);
  let [recipients, setRecipients] = useState<StoreCashupRecipient[]>(location.state?.store?.cashupRecipients);
  let [tillLayouts, setTillLayouts] = useState<TillLayoutResponse[]>(location.state?.tillLayouts);
  let [persons, setPersons] = useState<PersonResponse[]>(location.state?.persons);
  let [error, setError] = useState<string>(null);

  // console.log(`store = ${store}`);

  async function fetch() {
    try {
      let response = (await Axios.get(`/api/storeWithLinkedData/${storeId}`)).data;

      console.info(`Got store with linked data: ${JSON.stringify(response, null, 2)}`);

      let store = response.store as StoreResponse;
      let tillLayouts = response.tillLayouts as TillLayoutResponse[];
      let persons = response.persons as PersonResponse[];

      ReactDOM.unstable_batchedUpdates(() => {
        setStore(store);
        setTills(store.tills);
        setRecipients(store.cashupRecipients);
        setTillLayouts(tillLayouts);
        setPersons(persons);
        setError(null);
      });

      // console.info(`Got persons: ${JSON.stringify(persons, null, 2)}`);

    } catch (error) {
      console.log(error);

      setStore(null);
      setTillLayouts(null);
      setError(error.toString());
    }
  }

  useEffect(() => {
    if (!store) {
      fetch();
    }

  }, []);

  function handleEdit() {
    history.push(`/store/${storeId}/edit`, {
      store: store
    });
  }

  function handleDelete() {
    modal.show(
      <DialogModal
        title='Confirm'
        message={
          <span>Delete store <b>{store.name}</b>? This cannot be undone.</span>
        }
        actions={[
          {
            name: 'Delete',
            style: 'danger',
            handler: async () => {
              try {
                await Axios.delete(`/api/store/${storeId}`);

                flashContext.setMessage('Store deleted');
                history.push('/stores');

              } catch (error) {
                console.log(error);
                setError(error.toString());
              }
            }
          },
          'cancel'
        ]}
      />
    );
  }

  function handleAddTill(tillLayout: TillLayoutResponse) {
    // console.log(`Adding till: ${JSON.stringify(tillLayout)}`);

    let newTills = tills.concat({ layoutId: tillLayout.id });
    setTills(newTills);

    postTills(newTills);
  }

  function handleRemoveTill(index: number) {
    let newTills = [...tills];
    newTills.splice(index, 1);
    setTills(newTills);

    postTills(newTills);
  }

  async function postTills(tills: StoreTill[]) {
    try {
      await Axios.post(`/api/store/${storeId}/tills`, tills);
      setError(null);

      await fetch();

    } catch (error) {
      setError(error);
    }
  }

  function handleToggleRecipient(person: PersonResponse, selected: boolean) {
    if (!selected) {
      if (recipients.some(x => x.personId == person.id)) {
        let newRecipients = recipients.filter(x => x.personId != person.id);
        postRecipients(newRecipients);
      }

    } else {
      if (!recipients.some(x => x.personId == person.id)) {
        let newRecipients = recipients.concat({ personId: person.id });
        postRecipients(newRecipients);
      }
    }
  }

  async function postRecipients(recipients: StoreCashupRecipient[]) {
    try {
      await Axios.post(`/api/store/${storeId}/cashupRecipients`, recipients);
      setError(null);

      await fetch();

    } catch (error) {
      setError(error);
    }
  }

  return (
    <div>
      <Page.BackLink to='/stores'>Back To Stores</Page.BackLink>

      <Page.Header title={store?.name ?? 'Store'} />

      {error && <Page.Error>{error}</Page.Error>}

      <Pane>
        <React.Fragment>
          <Pane.Header>Store Details</Pane.Header>

          <Pane.Separator />

          {
            store ?
              <Pane.Content>
                <Pane.Content.FieldRow>
                  <Pane.Content.Field>
                    <label>Name</label>
                    <span>{store?.name}</span>
                  </Pane.Content.Field>
                  <Pane.Content.Field>
                    <label>Email</label>
                    <span>{store?.login}</span>
                  </Pane.Content.Field>
                </Pane.Content.FieldRow>

                <Pane.Content.Actions>
                  <a onClick={e => handleEdit()}>
                    <i className='far fa-pen'
                      style={{ fontSize: '0.8rem', marginRight: '0.23rem' }}
                    />
                    Edit Store
                  </a>
                </Pane.Content.Actions>
              </Pane.Content>
              :
              <div style={{ height: '5rem' }} />
          }
        </React.Fragment>

      </Pane>

      <Pane>
        <Pane.Header>
          <span style={{ flex: '1 1 auto' }}>Tills</span>
          {
            store &&
            <DropdownButton
              className={ElementStyles.button_link}
              dropdownContent={
                <DropdownMenu>
                  {
                    tillLayouts.map(tillLayout => {
                      return (
                        <li key={tillLayout.id}
                          style={{ width: '200px' }}
                          onClick={() => handleAddTill(tillLayout)}>
                          {tillLayout.name}
                        </li>
                      )
                    })}
                </DropdownMenu>
              }>
              <i className='far fa-plus fa-sm'
                style={{ marginRight: '0.25rem' }} />
              Add Till
            </DropdownButton>
          }
        </Pane.Header>

        <Pane.Separator />

        {
          store ?
            tills.length > 0 ?
              tills.map((till, index) => {
                let tillLayout = tillLayouts.find(layout => layout.id == till.layoutId)

                return (
                  <div key={till.layoutId} className={Styles.till}>
                    <div className={Styles.index}>
                      Till #{index + 1}
                    </div>
                    <div className={Styles.layout}>
                      Layout: <span className={Styles.name}>{tillLayout?.name ?? '(Missing Layout)'}</span>
                    </div>
                    <div className={Styles.actions}>
                      <Button buttonStyle='link-danger'
                        onClick={() => handleRemoveTill(index)}>
                        Remove
                      </Button>
                    </div>
                  </div>
                );
              })
              :
              <Pane.NoContent>
                There are no tills
              </Pane.NoContent>
            :
            <div style={{ height: '3rem' }} />
        }
      </Pane>

      <Pane>
        <Pane.Header>@ Email Recipients</Pane.Header>

        <Pane.Separator />

        {
          store ?
            <div>
              {
                persons.map(person => {
                  let selected = recipients.some(x => x.personId == person.id);

                  return (
                    <div key={person.id} className={Styles.person}>
                      <input type='checkbox'
                        id={`person-${person.id}`}
                        checked={selected}
                        onChange={e => handleToggleRecipient(person, e.target.checked)}
                      />
                      <label htmlFor={`person-${person.id}`}>{person.name}</label>
                    </div>
                  );
                })
              }
            </div>
            :
            <Pane.NoContent>
              No people
            </Pane.NoContent>
        }
      </Pane>

      {
        store &&
        <Pane>
          <Button buttonStyle='link-danger'
            onClick={handleDelete}>
            <i className='far fa-trash-alt' style={{ marginRight: '0.33rem' }} />
            Delete Store
          </Button>
        </Pane>
      }
    </div >
  );
}

export default StorePage;