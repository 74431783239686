import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Axios from 'axios';

import { Button, Page } from '/src/app/Elements';
import { format, parseISO, isSameDay } from 'date-fns';
import { useAuth, StoreAuthState } from '/src/app/AuthContext';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PulseSpinner from '/src/comps/PulseSpinner';
import { useMemoryState } from '/src/comps/Helpers';

const Styles = require('./StoreDashboardPage.scss');
// console.log(`Styles = ${JSON.stringify(Styles, null, 2)}`);

const StoreDashboardPage = () => {
  let history = useHistory();
  let auth = useAuth();

  let storeId = (auth.authState as StoreAuthState).storeId;

  let [cashups, setCashups] = useMemoryState<CashUp[]>(`store:${storeId}/cashups`, null);
  let [fetching, setFetching] = useState<boolean>(false);
  let [error, setError] = useState<string>(null);

  async function fetch() {
    try {
      console.info(`Getting cashups`);
      setFetching(true);

      let data = (await Axios.get('/api/currentStore/cashups')).data;
      console.info(`Got cashups: ${JSON.stringify(data, null, 2)}`);

      let cashups: CashUp[] = data.map(cashup => {
        return {
          ...cashup,
          cashupDate: parseISO(cashup.cashupDate),
          submittedDate: parseISO(cashup.submittedDate)
        };
      });

      setFetching(false);
      setCashups(cashups);
      setError(null);

    } catch (error) {
      console.error(error);

      setFetching(false);
      setError(error.toString());
    }
  }

  useEffect(() => {
    fetch();

    let firstOpen = true;

    let events = new EventSource(`/api/currentStore/cashups/events?auth=${auth.authState.token}`);

    events.onopen = () => {
      console.log(`Connected`);

      if (!firstOpen) {
        fetch();
      }

      firstOpen = false;
    }

    events.onmessage = () => fetch();

  }, [])

  function handleCashUp() {
    history.push('/store/newCashUp');
  }

  // console.log(`cashups = ${JSON.stringify(cashups)}`);

  return (
    <div className={Styles.container}>
      <Page.Header title={auth.storeInfo?.name} titleStyle='small'>
        <Button buttonStyle='primary' onClick={handleCashUp}>
          <i className='far fa-plus' style={{ marginRight: '0.35rem' }} />
          New Cash Up
        </Button>
      </Page.Header>

      {error && <Page.Error>{error.toString()}</Page.Error>}

      <div className={Styles.tableHeading}>
        <span>Recent Activites</span>
        {
          fetching &&
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PulseSpinner />
          </div>
        }
      </div>

      <TransitionGroup component={null}>
        {
          (cashups ?? []).map(cashup => {
            let isSubmittedToday = isSameDay(cashup.submittedDate, new Date());
            let submittedDate = isSubmittedToday ?
              format(cashup.submittedDate, `'Today' h:mm a`) :
              format(cashup.submittedDate, `d MMM yyyy, h:mm a`);

            return (
              <CSSTransition
                key={cashup.id}
                timeout={500}
                classNames={{
                  enter: Styles.enter,
                  enterActive: Styles.enterActive,
                }}
                unmountOnExit>
                <div key={cashup.id} className={Styles.tableRow}>
                  <span>Cash Up (for {format(cashup.cashupDate, 'd MMM yyyy')})</span>
                  <span>Submitted {submittedDate}</span>
                </div>
              </CSSTransition>

            );
          })
        }
      </TransitionGroup>
      <div className={Styles.tableLegend}>
        {
          (cashups == null || cashups.length > 0) ?
            'Only activities in past 7 days are shown' :
            'Nothing yet'
        }
      </div>

    </div >
  );
}

interface CashUp {
  id: string;
  storeName: string;
  cashupDate: Date;
  submittedDate: Date;
}

export default StoreDashboardPage;