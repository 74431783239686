@use '/src/App/Theme.scss' as theme;

aside.container {
  flex: 0 0 auto;
  padding-top: 1rem;
  padding-right: 2rem;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li a {
    display: block;
    padding: 0.5rem 0rem;
    color: theme.$text;
    font-weight: normal;
    font-size: 0.975rem;
    cursor: pointer;
    width: 8rem;

    &.selected {
      color: theme.$main-2;
      // color: #9C9157; // theme.$main-darker;
      font-weight: 600;
    }

    @media (hover: hover) {
      &:hover {
        color: rgba(theme.$main-2, 90%) !important;
      }
    }

    i {
      width: 1.5rem;
    }
  }
}
