import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Styles = require('./SideMenu.scss');

const SideMenu = () => {
  let location = useLocation();
  let path = location.pathname;

  let isProfile = path == '/profile';
  let isPersons = path.startsWith('/person');
  let isStores = path.startsWith('/store');
  let isTillLayouts = path.startsWith('/tillLayout');

  return (
    <aside className={Styles.container}>
      <ul>
        <li>
          <NavLink to='/stores' className={isStores ? Styles.selected : ''}>
            <i className='fas fa-database' style={{ fontSize: '1rem' }} />
            Stores
          </NavLink>
        </li>
        <li>
          <NavLink to='/tillLayouts' className={isTillLayouts ? Styles.selected : ''}>
            <i className='fas fa-th-large' style={{ fontSize: '1rem' }} />
            Till Layouts
          </NavLink>
        </li>
        <li>
          <NavLink to='/persons' className={isPersons ? Styles.selected : ''}>
            <i className='fas fa-user-cog' style={{ fontSize: '1rem' }} />
            People
          </NavLink>
        </li>
        <li>
          <Link to='/profile' className={isProfile ? Styles.selected : ''}>
            <i className='fas fa-user-circle' style={{ fontSize: '1rem' }} />
            My Profile
          </Link>
        </li>

      </ul>
    </aside>
  )
}

export default SideMenu;