import React from 'react';
import classNames from 'classnames';

import Theme from '~/app/Theme';
const Styles = require('./PulseSpinner.scss');

const PulseSpinner = ({ className, ...rest }: any) => {
  return (
    <div className={classNames(Styles.container, className)} {...rest}>
      <div className={Styles.pulse1} />
      <div className={Styles.pulse2} />
      <div className={Styles.pulse3} />
    </div>
  );
}

export default PulseSpinner;