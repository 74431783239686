import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

interface FlashContextValue {
  message?: string;
  setMessage: (message: string) => void;
}

export let FlashContext = React.createContext<FlashContextValue>(null);

export const FlashProvider = ({ children }) => {
  let location = useLocation();

  let [message, setMessage] = useState<string>(null);
  let [countdown, setCountdown] = useState<number>(0);

  useEffect(() => {
    if (countdown > 0) {
      setCountdown(countdown - 1);
    }

  }, [location.pathname]);

  useEffect(() => {
    setCountdown(2);
  }, [message]);

  let value: FlashContextValue = {
    message: countdown > 0 ? message : null,
    setMessage
  };

  return (
    <FlashContext.Provider value={value}>
      {children}
    </FlashContext.Provider>
  );
}

